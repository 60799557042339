// https://github.com/TanStack/table/issues/2071#issuecomment-679999096
import 'regenerator-runtime/runtime';
import { useEffect, useState } from 'react';
import analytics from '@analytics';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { SpeedInsights } from '@vercel/speed-insights/next';
import LogRocket from 'logrocket';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import numeral from 'numeral';
import { getCookieConsent } from '@leaf/helpers';
import '@ds/styles';
import { useApollo } from '@/apollo/client';
import ContextProvider from '@/contexts/context-provider';
import { setCookie } from '@/utils/logrocket-tracking-helpers';
import type { AppProps } from 'next/app';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/video/lib/plugin.css';
import 'draft-js/dist/Draft.css';

interface PageProps {
  initialApolloState?: NormalizedCacheObject;
}

interface Props extends AppProps<PageProps> {
  pageProps: PageProps;
}

const CustomApp: React.ComponentType<Props> = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps?.initialApolloState);
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);

  useEffect(() => {
    const cookieConsent = getCookieConsent();
    setAnalyticsAllowed(!!cookieConsent && cookieConsent.analytics);
    analytics.loadIfConsented();
    analytics.page(location.href, document.title, {
      path: location.pathname,
      referrer: window.frames.self.document.referrer,
      search: location.search,
      url: location.href,
    });

    if (
      process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID &&
      process.env.NODE_ENV === 'production'
    ) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID);
      LogRocket.getSessionURL((sessionURL) => {
        setCookie(sessionURL);
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
        analytics.track('LogRocket', {
          sessionURL: sessionURL,
        });
      });
    }
    // Numeral custom locale for capitals like "B" for billion instead of "b"
    // This is otherwise all the same as the 'en-au' locale
    if (numeral.locales['investor-hub'] === undefined) {
      numeral.register('locale', 'investor-hub', {
        abbreviations: {
          billion: 'B',
          million: 'M',
          thousand: 'K',
          trillion: 'T',
        },
        currency: {
          symbol: '$',
        },
        delimiters: {
          decimal: '.',
          thousands: ',',
        },
        ordinal: function (number) {
          const b = number % 10;
          return ~~((number % 100) / 10) === 1
            ? 'th'
            : b === 1
            ? 'st'
            : b === 2
            ? 'nd'
            : b === 3
            ? 'rd'
            : 'th';
        },
      });
    }
    // set the loaded local.
    numeral.locale('investor-hub');
  }, []);

  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => {
      NProgress.start();
    };
    const onRouteChangeComplete = (url: string) => {
      NProgress.done();
      const trackingProperties = {
        path: location.pathname,
        referrer: window.frames.self.document.referrer,
        search: location.search,
        url: location.href,
      };
      // Adding this because we stop use cookie and when redirect to login it will generate a new anonymousId which will increasing the cost
      if (!url.includes('login')) {
        analytics.page(url, document.title, trackingProperties);
      }
      if (
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
        process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID &&
        window.gtag
      ) {
        window.gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
          page_path: url,
        });
      }
    };
    const onRouteChangeError = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeError);
    };
  }, [router]);

  return (
    <>
      <DefaultSeo
        additionalLinkTags={[
          {
            href: '/apple-touch-icon.png',
            rel: 'apple-touch-icon',
            sizes: '180x180',
          },
          {
            href: '/favicon-32x32.png',
            rel: 'icon',
            sizes: '32x32',
            type: 'image/png',
          },
          {
            href: '/favicon-16x16.png',
            rel: 'icon',
            sizes: '16x16',
            type: 'image/png',
          },
          {
            href: '/site.webmanifest',
            rel: 'manifest',
          },
        ]}
        canonical={`https://app.investorhub.com${router.asPath}`}
        description="InvestorHub puts you in control of capital raises, enabling you to self-raise capital, increase your capital potential, and engage shareholders."
        openGraph={{
          images: [
            {
              height: 630,
              type: 'image/jpeg',
              url: 'https://www.investorhub.com/og-image.jpg',
              width: 1200,
            },
          ],
        }}
        title="InvestorHub - Shareholder engagement and capital raising for public companies"
        twitter={{
          cardType: 'summary_large_image',
          handle: '@frshxyz',
          site: '@frshxyz',
        }}
      />
      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
        analyticsAllowed && (
          <>
            <Script
              id="ga-script"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
              strategy="afterInteractive"
            />
            <Script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {
                page_path: window.location.pathname,
              });
              `,
              }}
              id="ga-script-init"
              strategy="afterInteractive"
            />
          </>
        )}
      <ApolloProvider client={apolloClient}>
        <ContextProvider>
          <Component {...pageProps} />
        </ContextProvider>
      </ApolloProvider>
      <SpeedInsights />
    </>
  );
};

export default CustomApp;
