import { ComponentType, ReactNode } from 'react';

export interface DividerProps {
  children?: ReactNode;
  line?: 'single' | 'double';
}

export const Divider: ComponentType<DividerProps> = ({
  children,
  line = 'single',
}) => {
  if (line === 'single') {
    return (
      <div className="flex items-center gap-2">
        <div className="h-px min-w-0 flex-1 bg-gray-200" />
        {children}
        <div className="h-px min-w-0 flex-1 bg-gray-200" />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center border-y border-y-gray-200 py-2.5">
      {children}
    </div>
  );
};

export default Divider;
