// DS V2
import React from 'react';

export const QuestionFill: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M16.3333 8C16.3333 10.1217 15.4904 12.1566 13.9901 13.6569C12.4898 15.1571 10.455 16 8.33325 16C6.21152 16 4.17669 15.1571 2.6764 13.6569C1.17611 12.1566 0.333252 10.1217 0.333252 8C0.333252 5.87827 1.17611 3.84344 2.6764 2.34315C4.17669 0.842855 6.21152 0 8.33325 0C10.455 0 12.4898 0.842855 13.9901 2.34315C15.4904 3.84344 16.3333 5.87827 16.3333 8V8ZM8.33325 5C8.15755 4.99983 7.9849 5.04595 7.8327 5.13373C7.68049 5.22151 7.5541 5.34784 7.46625 5.5C7.40282 5.61788 7.31636 5.72182 7.21201 5.80566C7.10766 5.8895 6.98754 5.95154 6.85877 5.9881C6.73 6.02466 6.5952 6.03499 6.46236 6.01849C6.32952 6.00198 6.20135 5.95897 6.08544 5.89201C5.96953 5.82505 5.86824 5.7355 5.78758 5.62867C5.70693 5.52184 5.64854 5.3999 5.61588 5.27008C5.58322 5.14027 5.57696 5.00522 5.59747 4.87294C5.61797 4.74065 5.66483 4.61384 5.73525 4.5C6.06547 3.92811 6.57516 3.48116 7.18528 3.22846C7.79539 2.97576 8.47184 2.93144 9.10972 3.10236C9.7476 3.27328 10.3113 3.64989 10.7133 4.1738C11.1153 4.6977 11.3332 5.33962 11.3333 6C11.3334 6.62062 11.1412 7.22603 10.783 7.73285C10.4248 8.23968 9.91833 8.62299 9.33325 8.83V9C9.33325 9.26522 9.22789 9.51957 9.04036 9.70711C8.85282 9.89464 8.59847 10 8.33325 10C8.06804 10 7.81368 9.89464 7.62615 9.70711C7.43861 9.51957 7.33325 9.26522 7.33325 9V8C7.33325 7.73478 7.43861 7.48043 7.62615 7.29289C7.81368 7.10536 8.06804 7 8.33325 7C8.59847 7 8.85282 6.89464 9.04036 6.70711C9.22789 6.51957 9.33325 6.26522 9.33325 6C9.33325 5.73478 9.22789 5.48043 9.04036 5.29289C8.85282 5.10536 8.59847 5 8.33325 5ZM8.33325 13C8.59847 13 8.85282 12.8946 9.04036 12.7071C9.22789 12.5196 9.33325 12.2652 9.33325 12C9.33325 11.7348 9.22789 11.4804 9.04036 11.2929C8.85282 11.1054 8.59847 11 8.33325 11C8.06804 11 7.81368 11.1054 7.62615 11.2929C7.43861 11.4804 7.33325 11.7348 7.33325 12C7.33325 12.2652 7.43861 12.5196 7.62615 12.7071C7.81368 12.8946 8.06804 13 8.33325 13V13Z"
        fill="#4D5761"
        fillRule="evenodd"
      />
    </svg>
  );
};
