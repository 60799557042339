import { createContext, useContext, useState } from 'react';
import ContactFreshModal from '@/components/modals/contact-fresh-modal';

interface ContactFreshContext {
  /**
   * 0 - General enquiry
   *
   * 1 - Product issues
   *
   * 2 - Subscriptions
   *
   * 3 - Share registry account
   *
   * 4 - Close account
   *
   * 5 - Email issues
   */
  showContactFreshModal(defaultSelection?: number | undefined): void;
}

const ContactFreshContext = createContext<ContactFreshContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showContactFreshModal() {},
});

export const ContactFreshProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [defaultSelection, setDefaultSelection] = useState(0);
  const [isContactFreshModalOpen, setContactFreshModalOpen] = useState(false);

  function showContactFreshModal(defaultSelection?: number) {
    setDefaultSelection(defaultSelection ?? 0);
    setContactFreshModalOpen(true);
  }

  return (
    <ContactFreshContext.Provider value={{ showContactFreshModal }}>
      {children}
      <ContactFreshModal
        defaultSelection={defaultSelection}
        open={isContactFreshModalOpen}
        onClose={() => setContactFreshModalOpen(false)}
      />
    </ContactFreshContext.Provider>
  );
};

export function useContactFresh() {
  return useContext(ContactFreshContext);
}
