import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Listbox } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  DuplicateIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Button from '@/components/ui/button';
import Typography from '@/components/ui/typography';
import { allCountries, Country } from '@/utils/countries';

export interface PhoneInputProps {
  copyable?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  label?: string;
  name?: string;
  number: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: (newValue: string) => void;
  placeholder?: string;
  selectedCountry: Country;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country>>;
  type?: HTMLInputTypeAttribute;
  value: string;
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      copyable,
      disabled,
      error,
      helperText,
      id,
      label,
      name,
      number,
      onBlur,
      onChange,
      placeholder,
      selectedCountry,
      setSelectedCountry,
      type = 'text',
      value,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current
    );

    const [isCopied, setCopied] = useState(false);

    const onCopy = useCallback(async () => {
      try {
        await navigator.clipboard.writeText(value);

        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (error) {
        setCopied(false);
      }
    }, [value]);

    const onChangeNumber = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const currentNumber = number;
        const updatedNumber = e.target.value;
        // if it's only the area code and this is a backspace, delete entire code
        if (
          currentNumber === `+${selectedCountry.code}` &&
          (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
        ) {
          onChange('');
          // if area code already in number, standard update of incoming
        } else if (currentNumber.includes(`+${selectedCountry.code}`)) {
          onChange(updatedNumber);
          // otherwise, add the area code in front of the incoming value
        } else {
          onChange(`+${selectedCountry.code}${updatedNumber}`);
        }
      },
      [number, onChange, selectedCountry.code]
    );

    const onSelectCountry = useCallback(
      (newCountry: Country) => {
        const existingCountry = selectedCountry;
        setSelectedCountry(newCountry);

        // where the area code already exists, do nothing
        if (number.includes(`+${newCountry.code}`)) {
          return;
          // otherwise, remove any previous area code and prepend the new area code
        } else {
          const numberWithExistingCountryRedacted = number.replaceAll(
            `+${existingCountry.code}`,
            ''
          );
          onChange(`+${newCountry.code}${numberWithExistingCountryRedacted}`);
        }
      },
      [number, onChange, selectedCountry, setSelectedCountry]
    );

    return (
      <div className="space-y-1.5">
        <label className="block" htmlFor={id}>
          <Typography
            className="text-gray-700"
            component="span"
            variant="text-label-sm"
          >
            {label}
          </Typography>
        </label>
        <div className={clsx('flex', copyable && 'shadow-xs rounded-lg')}>
          <div className="relative min-w-0 flex-1">
            <input
              ref={innerRef}
              className={clsx(
                'w-full bg-white py-2.5 pl-[72px] font-sans text-base font-normal leading-6 tracking-normal text-gray-900 placeholder:text-gray-500 focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:placeholder:text-gray-400',
                copyable ? 'rounded-l-lg' : 'shadow-xs rounded-lg border',
                error
                  ? 'border-red-300 pr-[38px] focus:border-red-300 focus:ring-red-100'
                  : 'focus:border-amplify-green-500 focus:ring-fresh-neon-100 border-gray-300 pr-3.5 disabled:border-gray-200',
                copyable && !error && 'border-r-0 focus:border-r'
              )}
              disabled={disabled}
              id={id}
              name={name}
              placeholder={placeholder}
              type={type}
              value={number}
              onBlur={onBlur}
              onChange={onChangeNumber}
            />
            <Listbox value={selectedCountry} onChange={onSelectCountry}>
              <Listbox.Button className="absolute inset-y-0 left-0 flex w-[72px] items-center justify-center gap-1">
                <Typography className="text-gray-900">
                  {selectedCountry.abbr}
                </Typography>
                <ChevronDownIcon className="h-5 w-5 text-gray-600" />
              </Listbox.Button>
              <Listbox.Options className="absolute inset-x-0 z-50 mt-2 overflow-hidden rounded-md bg-white shadow-lg">
                <div className="max-h-52 overflow-y-auto">
                  {allCountries.map((country) => (
                    <Listbox.Option
                      key={country.abbr}
                      className={clsx(
                        'flex cursor-pointer items-center justify-between gap-1 px-4 py-3',
                        country.abbr === selectedCountry.abbr
                          ? 'bg-amplify-green-25'
                          : 'hover:bg-gray-50'
                      )}
                      value={country}
                    >
                      <Typography
                        className="text-gray-900"
                        variant="text-body-sm"
                      >
                        {`${country.name} (${country.abbr}) +${country.code}`}
                      </Typography>
                      {country.abbr === selectedCountry.abbr && (
                        <CheckIcon className="h-5 w-5 text-gray-900" />
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Listbox>
            {error && (
              <ExclamationCircleIcon className="pointer-events-none absolute right-3.5 top-1/2 h-4 w-4 -translate-y-1/2 text-red-500" />
            )}
          </div>
          {copyable && (
            <div
              className={
                'relative rounded-r-lg border-y border-r border-gray-300'
              }
            >
              <Button
                LeadingIcon={isCopied ? undefined : DuplicateIcon}
                className="w-24"
                variant="tertiary-gray"
                onClick={onCopy}
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Button>
            </div>
          )}
        </div>
        <Typography
          className={error ? 'text-red-500' : 'text-gray-600'}
          variant="text-body-sm"
        >
          {helperText}
        </Typography>
      </div>
    );
  }
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
