import { ComponentType } from 'react';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export interface LinearProgressProps {
  labelPosition?:
    | 'right'
    | 'bottom'
    | 'top-floating'
    | 'bottom-floating'
    | 'none';
  value: number;
}

export const LinearProgress: ComponentType<LinearProgressProps> = ({
  labelPosition = 'none',
  value,
}) => {
  return (
    <div>
      <div className="flex items-center gap-3">
        <div className="group h-2 min-w-0 flex-1 rounded bg-gray-200">
          <div
            className="bg-amplify-green-700 relative h-2 rounded transition duration-200 ease-linear"
            style={{ width: `${value}%` }}
          >
            {(labelPosition === 'bottom-floating' ||
              labelPosition === 'top-floating') && (
              <div
                className={clsx(
                  'absolute right-0 hidden translate-x-1/2 items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 shadow-lg group-hover:flex',
                  labelPosition === 'bottom-floating' && 'top-4',
                  labelPosition === 'top-floating' && '-top-2 -translate-y-full'
                )}
              >
                <Typography
                  className="text-gray-700"
                  component="span"
                  variant="text-caption"
                >
                  {`${value}%`}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {labelPosition === 'right' && (
          <Typography
            className="text-gray-700"
            component="span"
            variant="text-caption"
          >
            {`${value}%`}
          </Typography>
        )}
      </div>
      {labelPosition === 'bottom' && (
        <Typography
          className="mt-2 text-right text-gray-700"
          component="div"
          variant="text-caption"
        >
          {`${value}%`}
        </Typography>
      )}
    </div>
  );
};

export default LinearProgress;
