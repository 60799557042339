// DS V2
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { DateRangePicker, Typography } from '@ds';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'usehooks-ts';

type Maybe<T> = T | null;

export interface DateSelectProps {
  customDates?: boolean;
  endDate: Maybe<Date>;
  fullWidth?: boolean;
  label?: React.ReactElement | 'no-label';
  maxDate?: Date;
  minDate?: Date;
  pastXDays?: 7 | 30 | 90 | 365;
  setEndDate: React.Dispatch<React.SetStateAction<Maybe<Date>>>;
  setStartDate: React.Dispatch<React.SetStateAction<Maybe<Date>>>;
  startDate: Maybe<Date>;
}

export const DateSelect: React.FC<DateSelectProps> = ({
  customDates,
  endDate,
  fullWidth,
  label,
  maxDate,
  minDate,
  pastXDays = 90,
  setEndDate,
  setStartDate,
  startDate,
}) => {
  type option = { days: number; name: string };
  const options: option[] = useMemo(
    () => [
      { days: 7, name: 'Past 7 days' },
      { days: 30, name: 'Past 30 days' },
      { days: 90, name: 'Past 90 days' },
      { days: 365, name: 'Past year' },
      { days: 0, name: 'Choose a date range' },
    ],
    []
  );

  const [rangePickerOpen, setRangePickerOpen] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState(
    customDates
      ? options[4]
      : options.find((option) => option.days === pastXDays) || options[2]
  );

  const selectedText = () => {
    const startDisplay = startDate ? dayjs(startDate).format('D/M/YYYY') : '';
    const endDisplay = endDate ? dayjs(endDate).format('D/M/YYYY') : '';
    if (selectedOption.name === options[4].name && (startDate || endDate)) {
      return `${startDisplay} - ${endDisplay}`;
    }
    return selectedOption.name;
  };

  const dateMinusDays = (days: number) =>
    new Date(new Date().setDate(new Date().getDate() - days));

  useEffect(() => {
    const now = new Date();
    if (selectedOption && selectedOption.name !== 'Choose a date range') {
      setStartDate(dateMinusDays(selectedOption.days));
      setEndDate(now);
    }
  }, [selectedOption, setStartDate, setEndDate]);

  useEffect(() => {
    if (!startDate || !endDate) {
      setSelectedOption(
        options.find((option) => option.days === pastXDays) as option
      );
    }
  }, [startDate, endDate, options, pastXDays]);

  const onChange = (option: option) => {
    setSelectedOption(option);
    if (option.name === 'Choose a date range') {
      setRangePickerOpen(true);
    }
  };

  const DatePickerWrapper: React.FC = (props) => {
    const ref = useRef(null);

    const handleClickOutside = () => {
      setRangePickerOpen(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
      <div ref={ref}>
        <DateRangePicker
          endDate={endDate}
          maxDate={maxDate}
          minDate={minDate}
          setEndDate={setEndDate}
          setOpen={setRangePickerOpen}
          setStartDate={setStartDate}
          startDate={startDate}
          {...props}
        />
      </div>
    );
  };

  return (
    <div className={clsx(fullWidth ? 'w-full md:w-auto' : 'w-auto md:w-auto')}>
      <Listbox value={selectedOption} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block">
              {label && label !== 'no-label' ? label : null}
              {!label ? (
                <Typography className="text-text-grey" variant="text-label-sm">
                  Date range
                </Typography>
              ) : null}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg border border-secondary-grey-light bg-white py-2 pl-3 pr-10 text-left text-base font-semibold text-text-main focus:border-secondary-grey-dark focus:outline-none">
                <span className="block truncate">{selectedText()}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon aria-hidden="true" className="h-7 w-7" />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <div>
                  {(selectedOption.name !== options[4].name ||
                    (open && !rangePickerOpen)) && (
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[200px] cursor-pointer overflow-auto rounded-md bg-white py-1 text-base font-normal shadow-lg ring-1 ring-black/5 focus:outline-none md:right-0">
                      {options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 px-4 ${
                              active
                                ? 'bg-amber-100 text-amber-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={option}
                        >
                          {({ selected }) => (
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {option.name}
                            </span>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  )}
                </div>
              </Transition>
              {rangePickerOpen && <DatePickerWrapper />}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default DateSelect;
