import { ComponentType, createElement, CSSProperties, ReactHTML } from 'react';
import clsx from 'clsx';
import styles from '@/components/ui/typography.module.css';

// Some of the variants have responsive version
// The text-display-xl will automatically show text-display-md on mobile responsive
// The text-display-lg will automatically show text-display-sm on mobile responsive
// The text-display-md will automatically show text-display-sm on mobile responsive
export type TypographyVariant =
  | 'text-display-xl'
  | 'text-display-lg'
  | 'text-display-md'
  | 'text-display-sm'
  | 'text-heading-xl'
  | 'text-heading-lg'
  | 'text-heading-md'
  | 'text-heading-sm'
  | 'text-subheading'
  | 'text-label-lg'
  | 'text-label-md'
  | 'text-label-sm'
  | 'text-body-lg'
  | 'text-body-md'
  | 'text-body-sm'
  | 'text-button-md'
  | 'text-button-sm'
  | 'text-caption-bold'
  | 'text-caption'
  | 'text-hyperlink-lg'
  | 'text-hyperlink-md'
  | 'text-hyperlink-sm'
  | 'text-hyperlink-caption';

export interface TypographyProps {
  children?: React.ReactNode;
  className?: string;
  component?: keyof ReactHTML | 'text';
  style?: CSSProperties;
  testId?: string;
  title?: string;
  variant?: TypographyVariant;
}

export const Typography: ComponentType<TypographyProps> = ({
  children,
  className,
  component = 'p',
  style,
  testId,
  title,
  variant = 'text-body-md',
}) => {
  return createElement(
    component,
    {
      className: clsx(styles[variant], className),
      'data-test': testId,
      style,
      title,
    },
    children
  );
};

export default Typography;
