/* eslint no-restricted-imports: 0 */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ApolloError } from '@apollo/client';
import {
  CompositeDecorator,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from 'draft-js';
import { superRichTextEditorDecorators } from '.';

const emptyContentState = convertFromRaw({
  blocks: [
    {
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: 'initial',
      text: '',
      type: 'unstyled',
    },
  ],
  entityMap: {},
});

interface EditorContext {
  editorState: EditorState;
  errorMessage: string;
  formatAndShowError: (error: unknown) => void;
  isExpanded: boolean;
  onBlur: () => void;
  rawText: string;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditorContext = createContext<EditorContext | null>(null);

interface EditorProps {
  children?: React.ReactNode;
  content?: string;
  defaultExpanded?: boolean;
  onChange?: (value: string) => void;
}

const EditorProvider: React.ComponentType<EditorProps> = ({
  children,
  content,
  defaultExpanded = false,
  onChange,
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      emptyContentState,
      new CompositeDecorator(superRichTextEditorDecorators)
    )
  );

  const [rawText, setRawText] = useState('');

  useEffect(() => {
    const isRichContent = () => {
      try {
        const parsed = JSON.parse(content || '');
        return !!parsed?.blocks?.length;
      } catch (_e) {
        return false;
      }
    };
    const initialContent =
      isRichContent() && content
        ? convertFromRaw(JSON.parse(content))
        : content
        ? ContentState.createFromBlockArray(
            convertFromHTML(content.replace(/\n/g, '<br/>')).contentBlocks
          )
        : null;

    const createdState = EditorState.createWithContent(
      initialContent || emptyContentState,
      new CompositeDecorator(superRichTextEditorDecorators)
    );

    setEditorState(createdState);
  }, [content]);

  useEffect(() => {
    setRawText(editorState.getCurrentContent().getPlainText());
  }, [editorState]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isExpanded, setExpanded] = useState(defaultExpanded);

  const formatAndShowError = useCallback((error: unknown) => {
    if (error instanceof ApolloError) {
      setErrorMessage(error.graphQLErrors.map((e) => e.message).join(' '));
    } else if (error instanceof Error) {
      setErrorMessage(error.message);
    } else if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      setErrorMessage('Oops! Something went wrong.');
    }

    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  }, []);

  const onBlur = useCallback(() => {
    if (onChange) {
      onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }
  }, [editorState, onChange]);

  return (
    <EditorContext.Provider
      value={{
        editorState,
        errorMessage,
        formatAndShowError,
        isExpanded,
        onBlur,
        rawText,
        setEditorState,
        setExpanded,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export function useEditor() {
  const context = useContext(EditorContext);

  if (!context) {
    throw new Error('useEditor must be used within a EditorProvider');
  }

  return context;
}

export function withEditorContext<T extends EditorProps>(
  Component: React.ComponentType<T>
) {
  const WithEditorContext: React.ComponentType<T> = (props) => {
    return (
      <EditorProvider
        content={props.content}
        defaultExpanded={props.defaultExpanded}
        onChange={props.onChange}
      >
        <Component {...props} />
      </EditorProvider>
    );
  };

  return WithEditorContext;
}
