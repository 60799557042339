// DS V2
import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useEffect } from 'react';
import { Button, Typography, TextSkeleton } from '@ds';
import { DuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { copyToClipboard } from '@/utils/common-helpers';

export interface TextInputWithInnerLabelProps {
  autoFocus?: boolean;
  className?: string;
  copyable?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  innerLabel: string;
  label?: string;
  max?: string | number | undefined;
  min?: string | number | undefined;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  testId?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
}

export const TextInputWithInnerLabel = forwardRef<
  HTMLInputElement,
  TextInputWithInnerLabelProps
>(
  (
    {
      autoFocus,
      className,
      copyable,
      disabled,
      error,
      helperText,
      id,
      innerLabel,
      label,
      max,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      placeholder,
      readOnly,
      required = false,
      style,
      testId,
      type = 'text',
      value,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current
    );

    const [isCopied, setCopied] = useState(false);

    const onCopy = useCallback(async () => {
      try {
        await copyToClipboard(innerRef.current?.value ?? '');

        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (error) {
        setCopied(false);
      }
    }, []);

    useEffect(() => {
      const innerLabelPlaceholderLength =
        document.getElementById('innerLabel')?.getAttribute('placeholder')
          ?.length ?? 0;

      document
        .getElementById('innerLabel')
        ?.setAttribute('size', `${innerLabelPlaceholderLength}`);
    }, [innerLabel]);

    return (
      <div className="space-y-1.5">
        {!!label && (
          <label className="block" htmlFor={id}>
            <Typography
              className="text-gray-700"
              component="span"
              variant="text-label-sm"
            >
              {label}
              {required ? ' *' : null}
            </Typography>
          </label>
        )}
        <div className={clsx('flex', copyable && 'shadow-xs rounded-lg')}>
          <div className="relative flex min-w-0 grow">
            {innerLabel ? (
              <input
                readOnly
                className={clsx(
                  'shadow-xs box-border cursor-not-allowed rounded-l-lg border border-gray-300 py-[9px] pl-3.5 font-sans text-base font-normal leading-6 tracking-normal text-gray-900 opacity-75 placeholder:text-gray-900 focus:outline-none'
                )}
                id="innerLabel"
                placeholder={innerLabel}
              />
            ) : (
              <div className="shadow-xs box-border w-[264px] rounded-l-lg border border-gray-300 px-3.5 py-[9px] font-sans text-base font-normal leading-6 tracking-normal text-gray-900 placeholder:text-gray-900 focus:ring-1 focus:ring-inset">
                <TextSkeleton className="w-full" variant="body" />
              </div>
            )}
            <input
              ref={innerRef}
              autoFocus={autoFocus}
              className={clsx(
                'shadow-xs box-border w-full rounded-r-lg border border-l-0 py-[9px] pl-3.5 font-sans text-base font-normal leading-6 tracking-normal text-gray-900 placeholder:text-gray-500 focus:ring-1 focus:ring-inset disabled:cursor-not-allowed disabled:bg-gray-50 disabled:placeholder:text-gray-400',
                error
                  ? 'border-red-300 pr-[38px] focus:border-red-300 focus:ring-red-100'
                  : 'focus:border-amplify-green-500 focus:ring-fresh-neon-100 border-gray-300 pr-3.5 disabled:border-gray-200',
                copyable && !error && 'border-r-0 focus:border-r',
                className
              )}
              data-test={testId}
              disabled={disabled}
              id={id}
              maxLength={max ? parseInt(max as string) : undefined}
              min={min}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              required={required}
              style={style}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onKeyPress={onKeyPress}
            />
            {error && (
              <ExclamationCircleIcon className="pointer-events-none absolute right-3.5 top-1/2 h-4 w-4 -translate-y-1/2 text-red-500" />
            )}
          </div>
          {copyable && (
            <div
              className={
                'relative rounded-r-lg border-y border-r border-gray-300'
              }
            >
              <Button
                LeadingIcon={isCopied ? undefined : DuplicateIcon}
                className="w-24"
                variant="tertiary-gray"
                onClick={onCopy}
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Button>
            </div>
          )}
        </div>
        {!!helperText && (
          <Typography
            className={error ? 'text-red-500' : 'text-gray-600'}
            variant="text-body-sm"
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
);

TextInputWithInnerLabel.displayName = 'TextInputWithInnerLabel';

export default TextInputWithInnerLabel;
