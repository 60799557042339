// Should be the same as the default url for company profile logo in leaf_be
export const COMPANY_LOGO_PLACEHOLDER_URL =
  'https://storage.googleapis.com/leaf-prod/placeholders/company-profile-logo-placeholder.png';

export const DEMO_WELCOME_PAGE_URL = 'https://mvl.investorhub.com/welcome';
export const DEMO_GOVERNANCE_PAGE_URL =
  'https://mvl.investorhub.com/governance';

// We are mapping common movement type values that we know as buy and sell
export const COMMON_MOVEMENT_TYPES = [
  'CHESS DAILY MOVEMENT DOWN',
  'CHESS DAILY MOVEMENT UP',
  'CHESS/CHESS MOVEMENT OFF',
  'CHESS/CHESS MOVEMENT ON',
  'HMV Holding Net Movement',
  'HoldingMovement',
  'MarketMovement',
];
