import querystring, { ParsedUrlQuery } from 'querystring';

// Use nested object
const routes = {
  auth: {
    forgotPassword: {
      href: '/auth/reset-password',
    },
    login: {
      href: '/auth/login',
    },
    resetPassword: {
      href: (token: string) => `/auth/reset-password/${token}`,
    },
  },
  confirmDetails: {
    href: (marketListingKey: string) => `/${marketListingKey}/confirm-details`,
  },
  engagement: {
    analytics: {
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/analytics/announcements`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/analytics`,
      investorHub: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/analytics/investor-hub`,
      },
      leadConversion: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/analytics/lead-conversion`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/analytics/updates`,
      },
    },
    analyticsV1: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/analytics-v1`,
    },
    audiences: {
      dynamicLists: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/audiences/contact-lists`,
        list: {
          createList: {
            href: (marketListingKey: string, query?: ParsedUrlQuery) =>
              query
                ? `/${marketListingKey}/engagement/audiences/contact-lists/create-list?${querystring.stringify(
                    query
                  )}`
                : `/${marketListingKey}/engagement/audiences/contact-lists/create-list`,
          },
          href: (
            marketListingKey: string,
            id: string,
            query?: ParsedUrlQuery
          ) =>
            query
              ? `/${marketListingKey}/engagement/audiences/contact-lists/${id}?${querystring.stringify(
                  query
                )}`
              : `/${marketListingKey}/engagement/audiences/contact-lists/${id}`,
        },
      },
      staticLists: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/audiences/tags`,
        list: {
          href: (
            marketListingKey: string,
            id: string,
            query?: ParsedUrlQuery
          ) =>
            query
              ? `/${marketListingKey}/engagement/audiences/tags/${id}?${querystring.stringify(
                  query
                )}`
              : `/${marketListingKey}/engagement/audiences/tags/${id}`,
        },
      },
    },
    campaigns: {
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/campaigns?tab=announcements`,
      },
      campaign: {
        emailContentEdit: {
          href: (marketListingKey: string, campaignId: string) =>
            `/${marketListingKey}/engagement/campaigns/${campaignId}/email-content-edit`,
        },
        href: (marketListingKey: string, campaignId: string) =>
          `/${marketListingKey}/engagement/campaigns/${campaignId}`,
      },
      href: (marketListingKey: string, tab?: 'announcements' | 'updates') =>
        tab
          ? `/${marketListingKey}/engagement/campaigns?tab=${tab}`
          : `/${marketListingKey}/engagement/campaigns`,
      interactiveMedia: {
        announcement: {
          href: (
            marketListingKey: string,
            announcementId: string,
            detailPage?: 'true'
          ) =>
            detailPage
              ? `/${marketListingKey}/engagement/campaigns/interactive-media/announcements/${announcementId}?detailPage=${detailPage}`
              : `/${marketListingKey}/engagement/campaigns/interactive-media/announcements/${announcementId}`,
        },
        update: {
          href: (
            marketListingKey: string,
            updateId: string,
            detailPage?: 'true'
          ) =>
            detailPage
              ? `/${marketListingKey}/engagement/campaigns/interactive-media/updates/${updateId}?detailPage=${detailPage}`
              : `/${marketListingKey}/engagement/campaigns/interactive-media/updates/${updateId}`,
        },
      },
      settings: {
        automatedDistribution: {
          announcements: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engagement/campaigns/settings/automated-distribution/announcements/configuration`,
            },
            viewSettings: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engagement/campaigns/settings/automated-distribution/announcements/view-settings`,
            },
          },
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engagement/campaigns/settings/automated-distribution`,
          updates: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engagement/campaigns/settings/automated-distribution/updates/configuration`,
            },
            viewSettings: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engagement/campaigns/settings/automated-distribution/updates/view-settings`,
            },
          },
          welcomeEmail: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engagement/campaigns/settings/automated-distribution/welcome-email/configuration`,
            },
          },
        },
        email: {
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engagement/campaigns/settings/email`,
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/campaigns/settings`,
        templates: {
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engagement/campaigns/settings/templates`,
        },
      },
      socials: {
        href: (
          marketListingKey: string,
          socialPlatform: 'linkedin' | 'twitter',
          mediaType: 'announcements' | 'updates',
          id: string
        ) =>
          `/${marketListingKey}/engagement/campaigns/socials/${socialPlatform}/${mediaType}/${id}`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/campaigns?tab=updates`,
      },
    },
    emailReputation: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/email-reputation`,
    },
    hubBuilder: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/hub-builder`,
      page: {
        block: {
          href: (marketListingKey: string, slug: string, blockId: string) =>
            `/${marketListingKey}/engagement/hub-builder/${slug}?blockId=${blockId}`,
        },
        editHeader: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engagement/hub-builder/${slug}?isEditingHeader=true`,
        },
        editSettings: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engagement/hub-builder/${slug}?isEditingSettings=true`,
        },
        editTheme: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engagement/hub-builder/${slug}?isEditingTheme=true`,
        },
        href: (marketListingKey: string, slug: string) =>
          `/${marketListingKey}/engagement/hub-builder/${slug}`,
      },
    },
    interactiveMedia: {
      announcement: {
        analytics: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engagement/interactive-media/announcements/${announcementId}?tab=analytics`,
        },
        edit: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engagement/interactive-media/announcements/${announcementId}/edit`,
        },

        href: (
          marketListingKey: string,
          announcementId: string,
          tab?: 'analytics' | 'distribution' | 'questions' | 'survey-responses'
        ) =>
          tab
            ? `/${marketListingKey}/engagement/interactive-media/announcements/${announcementId}?tab=${tab}`
            : `/${marketListingKey}/engagement/interactive-media/announcements/${announcementId}`,
        pdf: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engagement/interactive-media/announcements/${announcementId}/pdf`,
        },
      },
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/interactive-media?tab=announcements`,
      },
      href: (
        marketListingKey: string,
        tab?: 'announcements' | 'updates' | 'ai-summaries'
      ) =>
        tab
          ? `/${marketListingKey}/engagement/interactive-media?tab=${tab}`
          : `/${marketListingKey}/engagement/interactive-media`,
      manageQuestions: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/interactive-media/manage-questions`,
      },
      prepareAnnouncement: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/interactive-media?open=prep-announcement`,
      },
      preparedAnnouncement: {
        href: (marketListingKey: string, preparedAnnouncementId: string) =>
          `/${marketListingKey}/engagement/interactive-media/prepared-announcement/${preparedAnnouncementId}`,
      },
      settings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/interactive-media/settings`,
      },
      update: {
        edit: {
          href: (marketListingKey: string, updateId: string) =>
            `/${marketListingKey}/engagement/interactive-media/updates/${updateId}/edit`,
        },
        href: (marketListingKey: string, updateId: string, editPage?: 'true') =>
          editPage
            ? `/${marketListingKey}/engagement/interactive-media/updates/${updateId}?editPage=${editPage}`
            : `/${marketListingKey}/engagement/interactive-media/updates/${updateId}`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/interactive-media?tab=updates`,
      },
    },
    investorHub: {
      generalSettings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/investor-hub/general-settings`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/investor-hub`,
      managePages: {
        governancePageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engagement/investor-hub/manage-pages/governance-page-editor/edit`,
          },
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/investor-hub/manage-pages`,
        teamPageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engagement/investor-hub/manage-pages/team-page-editor/edit`,
          },
        },
        welcomePageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engagement/investor-hub/manage-pages/welcome-page-editor/edit`,
          },
        },
      },
      registeredInvestors: {
        certificate: {
          href: (marketListingKey: string, certificateId: string) =>
            `/${marketListingKey}/engagement/investor-hub/registered-investors/certificates/${certificateId}`,
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/investor-hub/registered-investors`,
      },
    },
    utmLinks: {
      create: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engagement/utm-links/create`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engagement/utm-links`,
      link: {
        href: (marketListingKey: string, id: string) =>
          `/${marketListingKey}/engagement/utm-links/${id}`,
      },
    },
  },
  home: {
    href: (marketListingKey: string) => `/${marketListingKey}`,
  },
  index: {
    href: '/',
  },
  intelligence: {
    boardReport: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/intelligence/board-report`,
    },
    keyMetrics: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/intelligence/key-metrics`,
    },
  },
  investors: {
    registerOverview: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/investors/register-overview`,
    },
    search: {
      contacts: {
        contact: {
          href: (marketListingKey: string, id: string) =>
            `/${marketListingKey}/investors/search/contacts/${id}`,
        },
      },
      href: (marketListingKey: string, query?: string) =>
        query
          ? `/${marketListingKey}/investors/search?${query}`
          : `/${marketListingKey}/investors/search`,
      importContactList: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/search/import-contact-list`,
      },
      register: {
        shareholder: {
          href: (marketListingKey: string, id: string) =>
            `/${marketListingKey}/investors/search/register/${id}`,
        },
      },
      shareholders: {
        shareholder: {
          href: (marketListingKey: string, id: string) =>
            `/${marketListingKey}/investors/search/register/${id}`,
        },
      },
    },
    shareholderInsights: {
      brokers: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/shareholder-insights/brokers`,
      },
      hnw: {
        href: (marketListingKey: string, query?: string) =>
          query
            ? `/${marketListingKey}/investors/shareholder-insights/hnw?${query}`
            : `/${marketListingKey}/investors/shareholder-insights/hnw`,
      },
      holdings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/shareholder-insights/holdings`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/investors/shareholder-insights`,
      profitability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/shareholder-insights/profitability`,
      },
      reachability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/shareholder-insights/reachability`,
      },
      tradingActivity: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/investors/shareholder-insights/trading-activity`,
      },
    },
  },
  myAccount: {
    href: (marketListingKey: string) => `/${marketListingKey}/my-account`,
    notificationPreferences: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/notification-preferences`,
    },
    privacyAndSecurity: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/privacy-and-security`,
    },
    profile: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/profile`,
    },
  },
  noOrganisation: {
    href: '/no-organisation',
  },
  raiseCapital: {
    pastPlacement: {
      href: (id: string, marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/past-placements/${id}`,
      resolveIssue: {
        href: (id: string, marketListingKey: string) =>
          `/${marketListingKey}/raise-capital/past-placements/${id}/resolve-issue`,
      },
    },
    pastPlacements: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/past-placements`,
    },
    placementEstimation: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/placement-estimation`,
    },
    shareholderOffer: {
      edit: {
        href: (id: string, marketListingKey: string) =>
          `/${marketListingKey}/raise-capital/shareholder-offers/${id}/edit`,
      },
      href: (id: string, marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/shareholder-offers/${id}`,
    },
    shareholderOffers: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/shareholder-offers`,
    },
    sppCalculator: {
      href: (ticker: string) => `/${ticker}/raise-capital/spp-calculator`,
    },
    sppEstimation: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/spp-estimation`,
    },
    sppEstimationV2: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/spp-estimation-v2`,
    },
    sppPreparation: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise-capital/spp-preparation`,
    },
  },
  settings: {
    email: {
      href: (marketListingKey: string) => `/${marketListingKey}/settings/email`,
    },
    general: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/general`,
    },
    href: (marketListingKey: string) => `/${marketListingKey}/settings`,
    investorHubDomain: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/investor-hub-domain`,
    },
    investorHubTheme: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/investor-hub-theme`,
    },
    registry: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/registry`,
    },
    socialMedia: {
      chooseLinkedinOrg: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/settings/social-media/choose-linkedin-org`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/social-media`,
    },
    team: {
      href: (marketListingKey: string) => `/${marketListingKey}/settings/team`,
    },
  },
};

export default routes;
