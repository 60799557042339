import { ComponentType, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import buttonStyles from '@/components/ui/button.module.css';
import dropdownStyles from '@/components/ui/dropdown.module.css';
import Typography from '@/components/ui/typography';
import { allCountries } from '@/utils/countries';

export const Dropdown: ComponentType = () => {
  const [selectedCountry, setSelectedCountry] = useState(allCountries[13]);

  return (
    <Listbox
      as="div"
      className="relative"
      value={selectedCountry}
      onChange={setSelectedCountry}
    >
      <Listbox.Button
        className={clsx(
          buttonStyles['base'],
          buttonStyles['pad-sm'],
          buttonStyles['secondary-gray'],
          buttonStyles['title-sm']
        )}
      >
        {selectedCountry.name}
        <ChevronDownIcon className="h-5 w-5" />
      </Listbox.Button>
      <Listbox.Options className={dropdownStyles['dropdown-container']}>
        {allCountries.map((item) => (
          <Listbox.Option
            key={item.abbr}
            className={clsx(
              dropdownStyles['dropdown-item'],
              item.abbr === selectedCountry.abbr
                ? dropdownStyles['dropdown-item-selected']
                : dropdownStyles['dropdown-item-unselected']
            )}
            value={item}
          >
            <Typography className="text-gray-900" variant="text-body-sm">
              {item.name}
            </Typography>
            {item.abbr === selectedCountry.abbr && (
              <CheckIcon className="h-5 w-5 text-gray-900" />
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default Dropdown;
