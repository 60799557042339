// DS V2
import {
  ComponentType,
  MouseEventHandler,
  ReactElement,
  SVGProps,
  useMemo,
} from 'react';
import {
  Typography,
  TypographyVariant,
  Badge,
  BadgeColor,
  BadgeSize,
} from '@ds';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

export const tagColors = [
  'amber',
  'cyan',
  'fresh-neon',
  'fuchsia',
  'gray',
  'moss',
  'rose',
  'sky',
  'sunflower',
  'violet',
] as const;

export type TagColor = (typeof tagColors)[number];

export interface TagProps {
  LeadingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  children?: React.ReactNode;
  color?: TagColor;
  count?: number;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const Tag: ComponentType<TagProps> = ({
  LeadingIcon = () => null,
  children,
  color,
  count,
  onDelete,
  size = 'md',
}) => {
  const badgeColor = useMemo<BadgeColor | undefined>(() => {
    switch (color) {
      case 'amber':
        return 'amber';
      case 'cyan':
        return 'cyan';
      case 'fresh-neon':
        return 'green';
      case 'fuchsia':
        return 'fuchsia';
      case 'gray':
        return 'gray';
      case 'moss':
        return 'moss';
      case 'rose':
        return 'rose';
      case 'sky':
        return 'sky';
      case 'sunflower':
        return 'sunflower';
      case 'violet':
        return 'violet';
    }
  }, [color]);

  const badgeSize = useMemo<BadgeSize | undefined>(() => {
    switch (size) {
      case 'sm':
        return 'xs';
      case 'md':
        return 'sm';
      case 'lg':
        return 'md';
      case 'xl':
        return 'md';
    }
  }, [size]);

  const labelVariant = useMemo<TypographyVariant | undefined>(() => {
    switch (size) {
      case 'sm':
        return 'text-caption';
      case 'md':
        return 'text-body-sm';
      case 'lg':
        return 'text-body-md';
      case 'xl':
        return 'text-body-md';
    }
  }, [size]);

  return (
    <div
      className={clsx(
        'inline-flex w-fit items-center justify-center gap-1 rounded-lg transition duration-200 ease-linear hover:shadow-sm',
        color === 'amber' &&
          'bg-amber-25 hover:bg-amber-50 active:bg-amber-50 active:shadow-none',
        color === 'cyan' &&
          'bg-cyan-25 hover:bg-cyan-50 active:bg-cyan-50 active:shadow-none',
        color === 'fresh-neon' &&
          'bg-fresh-neon-25 hover:bg-fresh-neon-50 active:bg-fresh-neon-50 active:shadow-none',
        color === 'fuchsia' &&
          'bg-fuchsia-25 hover:bg-fuchsia-50 active:bg-fuchsia-50 active:shadow-none',
        color === 'moss' &&
          'bg-moss-25 hover:bg-moss-50 active:bg-moss-50 active:shadow-none',
        color === 'gray' && 'bg-gray-100 active:bg-gray-50 active:shadow-none',
        color === 'rose' &&
          'bg-rose-25 hover:bg-rose-50 active:bg-rose-50 active:shadow-none',
        color === 'sky' &&
          'bg-sky-25 hover:bg-sky-50 active:bg-sky-50 active:shadow-none',
        color === 'sunflower' &&
          'bg-sunflower-25 hover:bg-sunflower-50 active:bg-sunflower-50 active:shadow-none',
        color === 'violet' &&
          'bg-purple-25 hover:bg-purple-50 active:bg-purple-50 active:shadow-none',
        size === 'sm' && 'px-2 py-0.5',
        size === 'md' && 'px-2 py-1',
        size === 'lg' && 'px-2 py-1',
        size === 'xl' && 'p-2'
      )}
    >
      <LeadingIcon
        className={clsx(
          color === 'amber' && 'text-amber-600',
          color === 'cyan' && 'text-cyan-600',
          color === 'fresh-neon' && 'text-fresh-neon-900',
          color === 'fuchsia' && 'text-fuchsia-600',
          color === 'gray' && 'text-gray-500',
          color === 'moss' && 'text-moss-600',
          color === 'rose' && 'text-rose-600',
          color === 'sky' && 'text-sky-600',
          color === 'sunflower' && 'text-sunflower-600',
          color === 'violet' && 'text-purple-600',
          size === 'sm' && 'h-2.5 w-2.5',
          size === 'md' && 'h-3 w-3',
          size === 'lg' && 'h-3.5 w-3.5',
          size === 'xl' && 'h-3.5 w-3.5'
        )}
      />
      <Typography
        className={clsx(
          color === 'amber' && 'text-amber-600',
          color === 'cyan' && 'text-cyan-600',
          color === 'fresh-neon' && 'text-fresh-neon-900',
          color === 'fuchsia' && 'text-fuchsia-600',
          color === 'gray' && 'text-gray-700',
          color === 'moss' && 'text-moss-600',
          color === 'rose' && 'text-rose-600',
          color === 'sky' && 'text-sky-600',
          color === 'sunflower' && 'text-sunflower-600',
          color === 'violet' && 'text-purple-600'
        )}
        component="span"
        variant={labelVariant}
      >
        {children}
      </Typography>
      {!!count && (
        <Badge color={badgeColor} size={badgeSize}>
          {count.toLocaleString()}
        </Badge>
      )}
      {onDelete && (
        <button type="button" onClick={onDelete}>
          <XIcon
            className={clsx(
              color === 'amber' && 'text-amber-600',
              color === 'cyan' && 'text-cyan-600',
              color === 'fresh-neon' && 'text-fresh-neon-900',
              color === 'fuchsia' && 'text-fuchsia-600',
              color === 'gray' && 'text-gray-500',
              color === 'moss' && 'text-moss-600',
              color === 'rose' && 'text-rose-600',
              color === 'sky' && 'text-sky-600',
              color === 'sunflower' && 'text-sunflower-600',
              color === 'violet' && 'text-purple-600',
              size === 'sm' && 'h-3.5 w-3.5',
              size === 'md' && 'h-4 w-4',
              size === 'lg' && 'h-5 w-5',
              size === 'xl' && 'h-[22px] w-[22px]'
            )}
          />
        </button>
      )}
    </div>
  );
};

export default Tag;
