import React from 'react';
import dayjs from 'dayjs';
import { Select } from '@/components/select';
import { getOffsettedDateObject } from '@/utils/timezone';

interface Props {
  /** Date to compare current date against for disabling hour and min values */
  compareDate?: Date | null;
  hours?: number;
  isBeforeCompareDate?: boolean;
  mins?: number;
  setHours: (hours?: number) => void;
  setMins: (mins?: number) => void;
  userTimeZoneOffset?: number;
}

export const TimePicker: React.FC<Props> = ({
  compareDate,
  hours,
  isBeforeCompareDate,
  mins,
  setHours,
  setMins,
  userTimeZoneOffset,
}) => {
  const maybeOffsettedCurrentDate = getOffsettedDateObject(
    new Date(),
    userTimeZoneOffset
  );

  return (
    <div className="grid grid-cols-2 items-center gap-4">
      <Select<number | undefined>
        options={[...Array(24).keys()].map((k) => ({
          disabled: compareDate
            ? dayjs(compareDate).isSame(
                dayjs(maybeOffsettedCurrentDate),
                'date'
              )
              ? isBeforeCompareDate
                ? k > dayjs(maybeOffsettedCurrentDate).hour()
                : k < dayjs(maybeOffsettedCurrentDate).hour()
              : false
            : false,
          label: k.toString().length === 1 ? `0${k}` : k.toString(),
          value: k,
        }))}
        placeholder="Hours"
        value={hours}
        onChange={(v) => setHours(v)}
      />
      <Select<number | undefined>
        options={[...Array(60).keys()].map((k) => ({
          disabled:
            compareDate && hours
              ? dayjs(compareDate).isSame(
                  dayjs(maybeOffsettedCurrentDate),
                  'date'
                ) && hours <= dayjs(maybeOffsettedCurrentDate).hour()
                ? isBeforeCompareDate
                  ? hours === dayjs(maybeOffsettedCurrentDate).hour()
                    ? k > dayjs(maybeOffsettedCurrentDate).minute()
                    : false
                  : k < dayjs(maybeOffsettedCurrentDate).minute()
                : false
              : false,
          label: k.toString().length === 1 ? `0${k}` : k.toString(),
          value: k,
        }))}
        placeholder="Mins"
        value={mins}
        onChange={(v) => setMins(v)}
      />
    </div>
  );
};

export default TimePicker;
