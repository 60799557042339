export const FlatIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeWidth="3"
      x1="4"
      x2="20"
      y1="11.5"
      y2="11.5"
    />
  </svg>
);

export default FlatIcon;
