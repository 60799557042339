import { ComponentType, CSSProperties } from 'react';
import clsx from 'clsx';

export interface IconSkeletonProps {
  className?: string;
  size: number;
  style?: CSSProperties;
}

export const IconSkeleton: ComponentType<IconSkeletonProps> = ({
  className,
  size,
  style,
}) => {
  return (
    <div
      className={clsx('animate-pulse rounded-lg bg-gray-100', className)}
      style={{ ...style, height: size, width: size }}
    />
  );
};

export default IconSkeleton;
