// DS V2
import { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Modal,
  TextArea,
  dropdownStyles,
  buttonStyles,
} from '@ds';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useContactUsMutation } from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';

interface FormData {
  message: string;
}

interface Props {
  defaultSelection?: number;
  onClose(): void;
  open: boolean;
}

const ENQUIRY_TYPES = [
  'General enquiry',
  'Product issues',
  'Subscriptions',
  'Share registry account',
  'Close account',
  'Email issues',
];

const ContactFreshModal: React.ComponentType<Props> = ({
  defaultSelection = 0,
  onClose,
  open,
}) => {
  const { formatAndShowError, showAlert } = useAlert();
  const [contactUs] = useContactUsMutation();

  const [selectedEnquiry, setSelectedEnquiry] = useState(
    ENQUIRY_TYPES[defaultSelection]
  );

  const { formState, handleSubmit, register, reset } = useForm<FormData>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().trim().required('Required.'),
      })
    ),
  });

  useEffect(() => {
    reset({
      message: '',
    });
    setSelectedEnquiry(ENQUIRY_TYPES[defaultSelection]);
  }, [defaultSelection, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await contactUs({
        variables: {
          message: data.message,
          type: selectedEnquiry,
        },
      });

      if (!result.data?.contactUs) {
        throw new Error('Unable to send enquiry.');
      }

      onClose();

      reset();

      setSelectedEnquiry(ENQUIRY_TYPES[defaultSelection]);

      showAlert({
        description:
          "We've received your message and a member of our team will be in touch with you shortly.",
        variant: 'success',
      });
    } catch (error) {
      formatAndShowError(error);
    }
  });

  return (
    <Modal className="sm:max-w-2xl" open={open} onClose={onClose}>
      <form className="space-y-8" onSubmit={onSubmit}>
        <div className="space-y-4">
          <Typography className="text-gray-900" variant="text-label-lg">
            Contact
          </Typography>
          <Typography className="text-gray-500" variant="text-body-md">
            Fill in the detail below and your Client Success Manager will be in
            touch with your soon.
          </Typography>
        </div>
        <div className="space-y-4">
          <div>
            <label className="mb-1 block" htmlFor="type">
              <Typography className="text-gray-700" variant="text-label-sm">
                Enquiry type
              </Typography>
            </label>
            <Listbox
              as="div"
              className="relative z-50"
              value={selectedEnquiry}
              onChange={(value: string) => {
                setSelectedEnquiry(value);
              }}
            >
              <Listbox.Button
                className={clsx(
                  buttonStyles['base'],
                  buttonStyles['pad-sm'],
                  buttonStyles['secondary-gray'],
                  buttonStyles['title-sm'],
                  'min-w-[220px] !justify-between'
                )}
              >
                {selectedEnquiry}
                <ChevronDownIcon className="h-5 w-5" />
              </Listbox.Button>
              <Listbox.Options className={dropdownStyles['dropdown-container']}>
                {ENQUIRY_TYPES.map((item) => (
                  <Listbox.Option
                    key={item}
                    className={clsx(
                      dropdownStyles['dropdown-item'],
                      item === selectedEnquiry
                        ? dropdownStyles['dropdown-item-selected']
                        : dropdownStyles['dropdown-item-unselected']
                    )}
                    value={item}
                  >
                    <Typography
                      className="text-gray-900"
                      component="span"
                      variant="text-body-sm"
                    >
                      {item}
                    </Typography>
                    {item === selectedEnquiry && (
                      <CheckIcon className="h-5 w-5 text-gray-900" />
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
          <TextArea
            {...register('message')}
            error={!!formState.errors.message?.message}
            helperText={formState.errors.message?.message}
            id="message"
            label="Please provide further details about your enquiry?"
            rows={5}
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row-reverse">
          <Button
            className="flex-1"
            disabled={formState.isSubmitting}
            type="submit"
            variant="primary"
          >
            Send enquiry
          </Button>
          <Button className="flex-1" variant="secondary-gray" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ContactFreshModal;
