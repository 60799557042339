import { ComponentType, MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export type RadioButtonLabel = {
  badge?: ReactNode;
  description?: string;
  layout?: 'horizontal' | 'vertical';
  title: string;
};

export interface RadioButtonProps {
  checked?: boolean;
  disabled?: boolean;
  label?: RadioButtonLabel;
  labelColorClass?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  radioGroup?: string;
  size?: 'sm' | 'md';
}

export const RadioButton: ComponentType<RadioButtonProps> = ({
  checked,
  disabled,
  label,
  labelColorClass = 'text-gray-800',
  onClick,
  radioGroup,
  size = 'sm',
}) => {
  return (
    <div
      className={clsx(
        label && label.layout === 'vertical' ? '' : 'items-center',
        'flex gap-2'
      )}
    >
      <button
        className={clsx(
          'text-amplify-green-700 hover:bg-fresh-neon-25 hover:border-amplify-green-800 focus:ring-fresh-neon-100 flex items-center justify-center rounded-full border bg-white outline-none focus:ring-4 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-300',
          checked ? 'border-amplify-green-700' : 'border-gray-300',
          !!label && 'mt-0.5',
          size === 'sm' && 'h-4 w-4',
          size === 'md' && 'h-5 w-5'
        )}
        disabled={disabled}
        id={radioGroup ? `${radioGroup}-${label?.title}` : label?.title}
        type="button"
        onClick={onClick}
      >
        {checked && (
          <div
            className={clsx(
              'h-2 w-2 rounded-full',
              disabled ? 'bg-gray-300' : 'bg-amplify-green-700'
            )}
          />
        )}
      </button>
      {!!label && (
        <label
          className={clsx(
            label.layout === 'vertical' ? 'flex flex-col gap-2' : 'contents',
            'min-w-0 flex-1',
            'cursor-pointer'
          )}
          htmlFor={radioGroup ? `${radioGroup}-${label.title}` : label.title}
        >
          {label.badge ? (
            <div className="leading-4">{label.badge}</div>
          ) : (
            <Typography
              className={labelColorClass}
              component="span"
              variant={size === 'sm' ? 'text-button-sm' : 'text-button-md'}
            >
              {label.title}
            </Typography>
          )}
          {label.description ? (
            <Typography
              className="text-gray-600"
              component="span"
              variant={size === 'sm' ? 'text-body-sm' : 'text-body-md'}
            >
              {label.description}
            </Typography>
          ) : null}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
