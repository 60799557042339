import { Fragment, useRef } from 'react';
import { Typography, TypographyVariant } from '@ds';
import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';

export type KebabMenuItem = {
  label: string;
  leadingIcon?: React.ReactNode;
  onClick: () => void;
};

interface Props {
  labelTextVariant?: string;
  menuItems?: {
    label: string;
    leadingIcon?: React.ReactNode;
    onClick: () => void;
  }[];
  verticalKebab?: boolean;
}

export const KebabMenu: React.ComponentType<Props> = ({
  labelTextVariant = 'text-button-sm',
  menuItems,
  verticalKebab = false,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    menuRef?.current?.click();
  };

  return (
    <Menu as="div" className="relative ">
      <div ref={menuRef} className="flex">
        <Menu.Button className="hover:bg-amplify-green-25 rounded-full p-2">
          {verticalKebab ? (
            <EllipsisVerticalIcon className={clsx('h-6 w-6 text-gray-700')} />
          ) : (
            <EllipsisHorizontalIcon className={clsx('h-6 w-6 text-gray-700')} />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            `absolute right-0 z-[1] mt-2 w-[144px] min-w-max origin-top-right overflow-hidden rounded-md border bg-white shadow-lg focus:outline-none`
          )}
        >
          {/* custom menu items */}
          {menuItems?.map((item) => (
            <Menu.Item key={item.label}>
              <>
                <button
                  className="focus:bg-amplify-green-200 hover:bg-amplify-green-25 flex w-full cursor-pointer items-center space-x-2 px-4 py-3 transition duration-100 ease-in-out"
                  role="button"
                  onClick={() => {
                    item.onClick();
                    closeMenu();
                  }}
                >
                  {item?.leadingIcon && (
                    <div className="h-4 w-4">{item.leadingIcon}</div>
                  )}

                  <Typography
                    className="text-gray-700"
                    component="span"
                    variant={labelTextVariant as TypographyVariant | undefined}
                  >
                    {item.label}
                  </Typography>
                </button>
              </>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default KebabMenu;
