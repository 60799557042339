import { ComponentType, useMemo } from 'react';
import clsx from 'clsx';

export interface CircularProgressProps {
  color: 'light-gray' | 'amplify-green' | 'dark-gray';
  size: 'xs' | 'sm' | 'md' | 'lg';
}

export const CircularProgress: ComponentType<CircularProgressProps> = ({
  color,
  size,
}) => {
  const fill1 = useMemo(() => {
    switch (color) {
      case 'amplify-green':
        return '#004036';
      case 'dark-gray':
        return '#111927';
      case 'light-gray':
        return '#E5E7EB';
    }
  }, [color]);

  const fill2 = useMemo(() => {
    switch (color) {
      case 'amplify-green':
        return '#437E76';
      case 'dark-gray':
        return '#6C737F';
      case 'light-gray':
        return '#9DA4AE';
    }
  }, [color]);

  return (
    <svg
      className={clsx(
        'animate-spin',
        size === 'xs' && 'h-5 w-5',
        size === 'sm' && 'h-6 w-6',
        size === 'md' && 'h-8 w-8',
        size === 'lg' && 'h-12 w-12'
      )}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10Z"
        fill={fill1}
      />
      <path
        d="M10 2C10 0.895431 10.9042 -0.0201237 11.9867 0.199334C13.3839 0.482563 14.7121 1.06288 15.8779 1.90983C17.5863 3.15111 18.858 4.9014 19.5106 6.90983C20.1631 8.91827 20.1631 11.0817 19.5106 13.0902C19.0653 14.4606 18.3318 15.7108 17.368 16.7611C16.6212 17.575 15.3515 17.3658 14.7023 16.4721V16.4721C14.053 15.5785 14.2888 14.3389 14.9208 13.433C15.257 12.9512 15.5226 12.4197 15.7063 11.8541C16.0979 10.649 16.0979 9.35096 15.7063 8.1459C15.3148 6.94084 14.5518 5.89067 13.5267 5.1459C13.0456 4.79634 12.5183 4.52248 11.9632 4.33027C10.9194 3.96886 10 3.10457 10 2V2Z"
        fill={fill2}
      />
    </svg>
  );
};

export default CircularProgress;
