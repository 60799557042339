import { ComponentType, Fragment } from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import Typography from '@/components/ui/typography';

type BreadcrumbsItem = {
  href: string;
  label: string;
};

export interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

export const Breadcrumbs: ComponentType<BreadcrumbsProps> = ({ items }) => {
  return (
    <div className="flex items-center gap-3">
      <HomeIcon className="h-5 w-5 text-gray-500" />
      {items.map((item, index) => (
        <Fragment key={item.label}>
          <ChevronRightIcon className="h-4 w-4 text-gray-300" />
          <Link href={item.href}>
            <Typography
              className={
                index === items.length - 1
                  ? 'text-amplify-green-700'
                  : 'text-gray-500'
              }
              component="span"
              variant="text-button-sm"
            >
              {item.label}
            </Typography>
          </Link>
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
