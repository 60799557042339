// DS V2
import { forwardRef } from 'react';
import clsx from 'clsx';
import styles from '@/components/ui/textarea.module.css';
import Typography from '@/components/ui/typography';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  // renders children below the input area
  children?: React.ReactNode;
  dark?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  optional?: boolean;
  placeholder?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      children,
      className,
      dark,
      disabled,
      error,
      helperText,
      id,
      label,
      optional,
      placeholder,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        {!!label && (
          <div className="mb-1 flex items-center justify-between space-x-2">
            <label htmlFor={id}>
              <Typography
                className={clsx('dark:text-white', dark && 'text-white')}
                component="span"
                variant="text-label-sm"
              >
                {label}
              </Typography>
            </label>
            {optional && (
              <Typography
                className={clsx(
                  'dark:text-white',
                  dark ? 'text-white' : 'text-text-grey'
                )}
              >
                Optional
              </Typography>
            )}
          </div>
        )}
        <div className="relative">
          <textarea
            {...props}
            ref={ref}
            className={clsx(
              styles['input'],
              error && styles['input-error'],
              className
            )}
            disabled={disabled}
            id={id}
            placeholder={placeholder}
          />
        </div>
        {!!helperText && (
          <Typography
            className={clsx('mt-2', error ? 'text-red-500' : 'text-gray-500')}
            variant="text-body-sm"
          >
            {helperText}
          </Typography>
        )}
        {children ? <div className="mt-2">{children}</div> : null}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
