import React from 'react';

export const EmptyStateInboxIcon: React.ComponentType = () => (
  <svg
    fill="none"
    height="56"
    viewBox="0 0 56 56"
    width="56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#F3F4F6" height="48" rx="24" width="48" x="4" y="4" />
    <path
      clipRule="evenodd"
      d="M22.9118 19C21.5948 19 20.4318 19.859 20.0445 21.1177L17.6327 28.9561C17.5447 29.2419 17.5 29.5393 17.5 29.8383V34C17.5 35.6569 18.8431 37 20.5 37H35.5C37.1569 37 38.5 35.6569 38.5 34V29.8383C38.5 29.5393 38.4553 29.2419 38.3673 28.9561L35.9555 21.1177C35.5682 19.859 34.4052 19 33.0882 19H22.9118ZM36.7345 28.75L34.5219 21.5589C34.3282 20.9295 33.7467 20.5 33.0882 20.5H22.9118C22.2533 20.5 21.6718 20.9295 21.4781 21.5589L19.2655 28.75H22.1094C23.2457 28.75 24.2845 29.392 24.7927 30.4084L25.0489 30.9208C25.303 31.429 25.8224 31.75 26.3906 31.75H29.6094C30.1776 31.75 30.697 31.429 30.9511 30.9208L31.2073 30.4084C31.7155 29.392 32.7543 28.75 33.8906 28.75H36.7345Z"
      fill="#0F172A"
      fillRule="evenodd"
    />
    <rect
      height="48"
      rx="24"
      stroke="#FCFCFD"
      strokeWidth="8"
      width="48"
      x="4"
      y="4"
    />
  </svg>
);
