// DS V2
import { ComponentType, ReactNode } from 'react';
import Typography from '@/components/ui/typography';

export interface SectionHeaderProps {
  buttons?: ReactNode;
  fullWidth?: boolean;
  heading: string | ReactNode;
  subheading?: string;
}

export const SectionHeader: ComponentType<SectionHeaderProps> = ({
  buttons,
  heading,
  subheading,
}) => {
  return (
    <div className="min-h-[68px] px-4 py-5 lg:px-0">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <Typography component="div" variant="text-heading-md">
          {heading}
        </Typography>
        {buttons ? buttons : null}
      </div>
      {subheading ? (
        <Typography className="text-gray-500" variant="text-body-sm">
          {subheading}
        </Typography>
      ) : null}
    </div>
  );
};

export default SectionHeader;
