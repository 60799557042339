// DS V2
import { ComponentType, JSX } from 'react';
import { Badge, Tooltip } from '@ds';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import styles from '@/components/ui/button.module.css';
import Typography from '@/components/ui/typography';

type TabItem = {
  badge?: JSX.Element;
  count?: number;
  customBorderClass?: string;
  customTextClass?: string;
  label: string;
  onClick: () => void;
  selected?: boolean;
  tooltipText?: string;
};

export interface TabsProps {
  className?: string;
  classNameMobile?: string;
  fullWidth?: boolean;
  items: TabItem[];
  testId?: string;
  variant?: 'button-base' | 'underline';
}

const renderTooltip = (item: TabItem) => {
  if (!item.tooltipText) return item.label;

  return (
    <Tooltip
      className="min-w-[150px] max-w-[240px] !rounded-lg !bg-gray-900 !shadow-lg"
      content={item.tooltipText}
      place="top"
    >
      {item.label}
    </Tooltip>
  );
};

export const Tabs: ComponentType<TabsProps> = ({
  className,
  classNameMobile,
  fullWidth,
  items,
  testId,
  variant = 'button-base',
}) => {
  const selectedItem = items.find((item) => item.selected);

  if (items.length === 1) {
    return (
      <div
        className="group flex items-center justify-center gap-2 px-1 pb-3.5 pt-0.5 outline-none"
        data-test={testId}
      >
        <Typography variant="text-heading-md">{items[0].label}</Typography>
      </div>
    );
  }

  return (
    <div className="w-full" data-test={testId}>
      {variant === 'button-base' && (
        <div
          className={clsx(
            'hidden items-stretch gap-2 sm:flex',
            fullWidth ? 'justify-evenly' : 'justify-start'
          )}
        >
          {items.map((item) => (
            <div key={item.label} className="group relative w-full">
              <button
                key={item.label}
                className={clsx(
                  'flex w-full items-center justify-center gap-2 rounded-md px-3 py-2 outline-none hover:bg-gray-50 focus:ring-1 focus:ring-gray-200',
                  !fullWidth && 'md:w-auto',
                  item.selected && 'bg-gray-100'
                )}
                type="button"
                onClick={item.onClick}
              >
                {/* background: linear-gradient(146.27deg, #8325FF 7.95%, #7FC6F4 131.2%); */}

                <Typography
                  className={clsx(
                    item.selected ? 'text-gray-700' : 'text-gray-500',
                    item.customTextClass && item.customTextClass
                  )}
                  variant="text-label-sm"
                >
                  {renderTooltip(item)}
                </Typography>
                {!!item.count && (
                  <Badge color="gray" size="xs" testId="tab-badge">
                    {item.count.toLocaleString()}
                  </Badge>
                )}
              </button>
            </div>
          ))}
        </div>
      )}
      {variant === 'underline' && (
        <div
          className={clsx(
            'tabs-border hidden w-full items-stretch gap-4 sm:flex',
            fullWidth ? 'justify-evenly' : 'justify-start',
            className
          )}
        >
          {items.map((item) => (
            <div key={item.label} className="group relative">
              <button
                key={item.label}
                className={clsx(
                  'group flex items-center justify-center gap-2 border-b-2 px-1 pb-3.5 pt-0.5 outline-none',
                  item.selected
                    ? item.customBorderClass
                      ? item.customBorderClass
                      : 'border-b-amplify-green-700'
                    : 'hover:border-b-amplify-green-700 focus:border-b-amplify-green-700 border-b-transparent'
                )}
                type="button"
                onClick={item.onClick}
              >
                <Typography
                  className={clsx(
                    item.selected
                      ? item.customTextClass
                        ? item.customTextClass
                        : 'text-amplify-green-700'
                      : 'group-hover:text-amplify-green-700 text-gray-500'
                  )}
                  variant="text-label-md"
                >
                  {renderTooltip(item)}
                </Typography>

                {!!item.badge && item.badge}

                {!!item.count && (
                  <Badge
                    color={item.selected ? 'green' : 'gray'}
                    size="xs"
                    testId="tab-badge"
                  >
                    {item.count.toLocaleString()}
                  </Badge>
                )}
              </button>
            </div>
          ))}
        </div>
      )}
      {/* Listbox on mobile size */}
      <Listbox
        as="div"
        className={clsx(
          'relative z-40 block w-full sm:hidden',
          !fullWidth && 'md:w-auto',
          classNameMobile
        )}
        value={selectedItem?.label}
        onChange={(newItem: string) => {
          const newSelectedItem = items.find((item) => item.label === newItem);
          newSelectedItem?.onClick();
        }}
      >
        <Listbox.Button
          className={clsx(
            styles['base'],
            styles['pad-sm'],
            styles['secondary-gray'],
            styles['title-sm'],
            'w-full !justify-between'
          )}
        >
          <div className="space-x-1">
            <span>{selectedItem?.label}</span>
            {!!selectedItem?.count && (
              <Badge
                color={selectedItem?.selected ? 'green' : 'gray'}
                size="xs"
                testId="tab-badge"
              >
                {selectedItem?.count.toLocaleString()}
              </Badge>
            )}
          </div>

          <ChevronDownIcon className="h-5 w-5" />
        </Listbox.Button>
        <Listbox.Options className="absolute inset-x-0 mt-2 overflow-hidden rounded-md bg-white shadow-lg">
          {items.map((item) => (
            <Listbox.Option
              key={item.label}
              className={clsx(
                'flex cursor-pointer items-center justify-between gap-1 px-4 py-3',
                item.selected ? 'bg-amplify-green-25' : 'hover:bg-gray-50'
              )}
              value={item.label}
            >
              <Typography
                className="text-gray-900"
                component="div"
                variant="text-body-sm"
              >
                <div className="space-x-1">
                  <span>{item?.label}</span>
                  {!!item.count && (
                    <Badge
                      color={item.selected ? 'green' : 'gray'}
                      size="xs"
                      testId="tab-badge"
                    >
                      {item.count.toLocaleString()}
                    </Badge>
                  )}
                </div>
              </Typography>
              {item.selected && <CheckIcon className="h-5 w-5 text-gray-900" />}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default Tabs;
