import { ApolloError } from '@apollo/client';

export function formatError(error: unknown) {
  if (error instanceof ApolloError) {
    const errorMessages = error.graphQLErrors
      .map((e) => e.message)
      .join(' ')
      .trim();

    return errorMessages || error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'Oops! Something went wrong.';
}
