// DS V2
import { ComponentType, CSSProperties, useMemo } from 'react';
import clsx from 'clsx';
import styles from '@/components/ui/card.module.css';

export interface CardProps {
  blurContent?: React.ReactNode;
  bordered?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hover?: boolean;
  onClick?: () => void;
  selected?: boolean;
  style?: CSSProperties;
  transparent?: boolean;
}

export const Card: ComponentType<CardProps> = ({
  blurContent,
  bordered,
  children,
  className,
  disabled,
  hover,
  onClick,
  selected,
  style,
  transparent,
}) => {
  const classNames = useMemo(() => {
    if (selected) {
      return styles.selected;
    }

    if (bordered) {
      if (disabled) {
        return styles['disabled-bordered'];
      }

      return styles['default-bordered'];
    }

    if (disabled) {
      return styles.disabled;
    }

    if (transparent) {
      return styles.transparent;
    }

    return styles.default;
  }, [bordered, disabled, selected, transparent]);

  if (blurContent) {
    return (
      <div
        className={clsx(
          classNames,
          className,
          hover &&
            'transition duration-200 ease-linear hover:bg-gray-50 hover:shadow-md'
        )}
        style={style}
        onClick={onClick}
      >
        <div className="relative">
          {children}
          <div className="absolute left-0 top-0 grid h-full w-full items-start justify-center bg-[#384250]/[0.4] pt-[20%] lg:place-items-center lg:pt-0">
            {blurContent}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        classNames,
        className,
        hover &&
          'transition duration-200 ease-linear hover:bg-gray-50 hover:shadow-md'
      )}
      style={style}
      onClick={onClick}
    >
      <div className="relative">{children}</div>
    </div>
  );
};

export default Card;
