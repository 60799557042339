// DS V2
import React, { SVGProps } from 'react';

interface Props {
  props?: SVGProps<SVGSVGElement>;
  variant?: 'search' | 'pencil' | 'person';
}

export const EmptyStateIcon: React.ComponentType<Props> = ({
  props,
  variant = 'search',
}) => {
  return (
    <svg
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#DAFCF2" height="48" rx="24" width="48" x="4" y="4" />
      {variant === 'search' ? (
        <path
          d="M36.4697 37.5303C36.7626 37.8232 37.2374 37.8232 37.5303 37.5303C37.8232 37.2374 37.8232 36.7626 37.5303 36.4697L36.4697 37.5303ZM26.5 33.25C22.7721 33.25 19.75 30.2279 19.75 26.5H18.25C18.25 31.0563 21.9437 34.75 26.5 34.75V33.25ZM19.75 26.5C19.75 22.7721 22.7721 19.75 26.5 19.75V18.25C21.9437 18.25 18.25 21.9437 18.25 26.5H19.75ZM26.5 19.75C30.2279 19.75 33.25 22.7721 33.25 26.5H34.75C34.75 21.9437 31.0563 18.25 26.5 18.25V19.75ZM37.5303 36.4697L32.3336 31.273L31.273 32.3336L36.4697 37.5303L37.5303 36.4697ZM33.25 26.5C33.25 28.3642 32.4953 30.0506 31.273 31.273L32.3336 32.3336C33.8258 30.8415 34.75 28.778 34.75 26.5H33.25ZM31.273 31.273C30.0506 32.4953 28.3642 33.25 26.5 33.25V34.75C28.778 34.75 30.8415 33.8258 32.3336 32.3336L31.273 31.273Z"
          fill="#077656"
        />
      ) : null}
      {variant === 'pencil' ? (
        <path
          d="M19.7344 33.7134L18.2203 37.4987C18.0243 37.9885 18.5104 38.4746 19.0002 38.2787L22.7855 36.7645C23.3891 36.5231 23.9373 36.1616 24.397 35.702L37.5 22.5995C38.4941 21.6054 38.4941 19.9936 37.5 18.9995C36.5059 18.0054 34.8941 18.0054 33.9 18.9995L20.797 32.102C20.3373 32.5616 19.9758 33.1099 19.7344 33.7134Z"
          fill="#077656"
        />
      ) : null}
      {variant === 'person' ? (
        <>
          <path
            clipRule="evenodd"
            d="M24.0008 22C24.0008 19.5147 26.0155 17.5 28.5008 17.5C30.9861 17.5 33.0008 19.5147 33.0008 22C33.0008 24.4853 30.9861 26.5 28.5008 26.5C26.0155 26.5 24.0008 24.4853 24.0008 22Z"
            fill="#077656"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M20.2521 36.1053C20.3294 31.6156 23.9928 28 28.5008 28C33.0089 28 36.6724 31.6157 36.7496 36.1056C36.7547 36.4034 36.5832 36.676 36.3125 36.8002C33.9335 37.8918 31.2873 38.5 28.5011 38.5C25.7147 38.5 23.0683 37.8917 20.6891 36.7999C20.4185 36.6757 20.2469 36.4031 20.2521 36.1053Z"
            fill="#077656"
            fillRule="evenodd"
          />
        </>
      ) : null}
      <rect
        height="48"
        rx="24"
        stroke="#F5FFFC"
        strokeWidth="8"
        width="48"
        x="4"
        y="4"
      />
    </svg>
  );
};
