/* eslint no-restricted-imports: 0 */
import { useMemo } from 'react';
import punycode from 'punycode';
import { Typography } from '@ds';
import clsx from 'clsx';
import { useEditor } from './context';
import type { EditorState } from 'draft-js';

export interface CounterProps {
  limit: number;
  type: 'char' | 'word';
}

function getCharCount(editorState: EditorState): number {
  const decodeUnicode = (str: string): number[] => punycode.ucs2.decode(str); // func to handle unicode characters
  const plainText = editorState.getCurrentContent().getPlainText('');
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, '').trim(); // replace above characters w/ nothing

  return decodeUnicode(cleanString).length;
}

function getWordCount(editorState: EditorState): number {
  const plainText = editorState.getCurrentContent().getPlainText('');
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, ' ').trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace

  return wordArray ? wordArray.length : 0;
}

const Counter: React.ComponentType<CounterProps> = ({ limit, type }) => {
  const { editorState } = useEditor();

  const content = useMemo(() => {
    switch (type) {
      case 'char':
        return `${getCharCount(editorState)}/${limit} characters`;
      case 'word':
        return `${getWordCount(editorState)}/${limit} words`;
    }
  }, [editorState, limit, type]);

  const isOverLimit = useMemo(() => {
    switch (type) {
      case 'char':
        return getCharCount(editorState) > limit;
      case 'word':
        return getWordCount(editorState) > limit;
    }
  }, [editorState, limit, type]);

  return (
    <div className="p-2">
      <Typography
        className={clsx(
          isOverLimit ? 'text-status-red' : 'text-text-grey',
          'text-right'
        )}
        variant="text-body-sm"
      >
        {content}
      </Typography>
    </div>
  );
};

export default Counter;
