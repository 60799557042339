// DS V2
import { ReactElement, SVGProps, useState } from 'react';
import { Button } from '@ds';
import { copyToClipboard } from '@/utils/common-helpers';

interface Props {
  LeadingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  className?: string;
  text: string;
  title?: string;
}

export const CopyTextButton: React.FC<Props> = ({
  LeadingIcon,
  className,
  text,
  title = 'Copy',
}) => {
  const [copied, setCopied] = useState(false);

  return (
    <Button
      LeadingIcon={copied ? undefined : LeadingIcon}
      className={className}
      variant="secondary-gray"
      onClick={async () => {
        if (!copied) {
          await copyToClipboard(text);
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        }
      }}
    >
      {copied ? 'Copied!' : title}
    </Button>
  );
};

export default CopyTextButton;
