// DS V2
export const AIWriteIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1093 1.8907C17.255 1.03643 15.87 1.03643 15.0157 1.8907L14.0514 2.855L17.145 5.94859L18.1093 4.9843C18.9636 4.13002 18.9636 2.74498 18.1093 1.8907Z"
        fill="url(#paint0_linear_926_1043)"
      />
      <path
        d="M16.2611 6.83248L13.1675 3.73888L3.04183 13.8646C2.52784 14.3786 2.15002 15.0125 1.9425 15.7092L1.27602 17.9466C1.2105 18.1665 1.27079 18.4047 1.43307 18.5669C1.59534 18.7292 1.8335 18.7895 2.05344 18.724L4.29085 18.0575C4.98749 17.85 5.62145 17.4722 6.13544 16.9582L16.2611 6.83248Z"
        fill="url(#paint1_linear_926_1043)"
      />
      <path
        clipRule="evenodd"
        d="M5.57276 0C5.71398 0 5.8381 0.0936181 5.87689 0.229407L6.21985 1.42975C6.36996 1.95514 6.78064 2.36582 7.30603 2.51593L8.50637 2.85888C8.64216 2.89768 8.73578 3.02179 8.73578 3.16302C8.73578 3.30424 8.64216 3.42835 8.50637 3.46715L7.30603 3.8101C6.78064 3.96021 6.36996 4.3709 6.21985 4.89629L5.87689 6.09663C5.8381 6.23242 5.71398 6.32603 5.57276 6.32603C5.43154 6.32603 5.30743 6.23242 5.26863 6.09663L4.92568 4.89629C4.77556 4.3709 4.36488 3.96021 3.83949 3.8101L2.63915 3.46715C2.50336 3.42835 2.40974 3.30424 2.40974 3.16302C2.40974 3.02179 2.50336 2.89768 2.63915 2.85888L3.83949 2.51593C4.36488 2.36582 4.77556 1.95514 4.92568 1.42975L5.26863 0.229407C5.30743 0.0936181 5.43154 0 5.57276 0Z"
        fill="url(#paint2_linear_926_1043)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.0609 11.7836C15.1954 11.7836 15.3136 11.8708 15.3505 11.9972L15.6771 13.1152C15.8201 13.6045 16.2112 13.987 16.7115 14.1268L17.8546 14.4462C17.9839 14.4823 18.0731 14.5979 18.0731 14.7295C18.0731 14.861 17.9839 14.9766 17.8546 15.0127L16.7115 15.3321C16.2112 15.4719 15.8201 15.8544 15.6771 16.3437L15.3505 17.4617C15.3136 17.5882 15.1954 17.6753 15.0609 17.6753C14.9264 17.6753 14.8082 17.5882 14.7713 17.4617L14.4447 16.3437C14.3017 15.8544 13.9106 15.4719 13.4103 15.3321L12.2672 15.0127C12.1379 14.9766 12.0487 14.861 12.0487 14.7295C12.0487 14.5979 12.1379 14.4823 12.2672 14.4462L13.4103 14.1268C13.9106 13.987 14.3017 13.6045 14.4447 13.1152L14.7713 11.9972C14.8082 11.8708 14.9264 11.7836 15.0609 11.7836Z"
        fill="url(#paint3_linear_926_1043)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.80731 5.89172C1.888 5.89172 1.95892 5.94404 1.98108 6.01992L2.17704 6.69068C2.26282 6.98428 2.49748 7.21377 2.79768 7.29766L3.48354 7.4893C3.56112 7.51098 3.61462 7.58034 3.61462 7.65926C3.61462 7.73817 3.56112 7.80753 3.48354 7.82921L2.79768 8.02086C2.49748 8.10474 2.26282 8.33424 2.17704 8.62783L1.98108 9.29859C1.95892 9.37448 1.888 9.42679 1.80731 9.42679C1.72661 9.42679 1.6557 9.37448 1.63353 9.29859L1.43757 8.62783C1.3518 8.33424 1.11714 8.10474 0.816939 8.02086L0.13108 7.82921C0.0534922 7.80753 0 7.73817 0 7.65926C0 7.58034 0.0534922 7.51098 0.13108 7.4893L0.816939 7.29766C1.11714 7.21377 1.3518 6.98428 1.43757 6.69068L1.63353 6.01992C1.6557 5.94404 1.72661 5.89172 1.80731 5.89172Z"
        fill="url(#paint4_linear_926_1043)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_926_1043"
          x1="2.24999"
          x2="17.875"
          y1="1.625"
          y2="18.25"
        >
          <stop offset="0.03" stopColor="#8325FF" />
          <stop offset="0.875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_926_1043"
          x1="2.24999"
          x2="17.875"
          y1="1.625"
          y2="18.25"
        >
          <stop offset="0.03" stopColor="#8325FF" />
          <stop offset="0.875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_926_1043"
          x1="2.77123"
          x2="8.41948"
          y1="0.135558"
          y2="6.14529"
        >
          <stop offset="0.03" stopColor="#8325FF" />
          <stop offset="0.875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_926_1043"
          x1="12.393"
          x2="17.6449"
          y1="11.9098"
          y2="17.6237"
        >
          <stop offset="0.03" stopColor="#8325FF" />
          <stop offset="0.875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_926_1043"
          x1="0.206549"
          x2="3.35773"
          y1="5.96748"
          y2="9.39578"
        >
          <stop offset="0.03" stopColor="#8325FF" />
          <stop offset="0.875" stopColor="#7FC6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AIWriteIcon;
