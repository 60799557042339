'use client';
import { Block, BlockNoteEditor, filterSuggestionItems } from '@blocknote/core';
import {
  BlockNoteView,
  SuggestionMenuController,
  useCreateBlockNote,
  DefaultReactSuggestionItem,
  getDefaultReactSlashMenuItems,
  BlockTypeSelect,
  FormattingToolbarController,
  FormattingToolbar,
  BasicTextStyleButton,
  CreateLinkButton,
  SideMenuController,
  SideMenu,
  DragHandleMenu,
  RemoveBlockItem,
} from '@blocknote/react';
import '@blocknote/react/style.css';

// List containing all Headings and Basic blocks Slash Menu Items
const getCustomSlashMenuItems = (
  editor: BlockNoteEditor
): DefaultReactSuggestionItem[] =>
  getDefaultReactSlashMenuItems(editor).filter(
    (slashMenuItem) =>
      slashMenuItem.group === 'Headings' ||
      slashMenuItem.group === 'Basic blocks'
  );

// Spike: Notion-like rich text editor
export const BlockNote = function BlockNote({
  initialContent,
  onChange,
}: {
  initialContent: Block[] | undefined;
  onChange: (changes: Block[]) => void;
}) {
  const editor = useCreateBlockNote({ initialContent: initialContent });

  const handleChange = async () => {
    onChange(editor.document);
  };

  return (
    <div className="shadow-xs focus:border-amplify-green-500 focus:ring-fresh-neon-100 relative rounded-lg border border-gray-300 py-2 disabled:border-gray-200">
      <BlockNoteView
        data-customized-css
        editor={editor}
        formattingToolbar={false}
        sideMenu={false}
        slashMenu={false}
        theme={'light'}
        onChange={handleChange}
      >
        <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              dragHandleMenu={(props) => (
                // Only keep RemoveBlockItem button in DragHandleMenu
                <DragHandleMenu {...props}>
                  <RemoveBlockItem {...props}>Delete</RemoveBlockItem>
                </DragHandleMenu>
              )}
              {...props}
            />
          )}
        />
        {/* Customized toolbar containing all BlockTypeSelect, BasicTextStyleButton,
        CreateLinkButton buttons */}
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              <BlockTypeSelect key={'blockTypeSelect'} />

              <BasicTextStyleButton
                key={'boldStyleButton'}
                basicTextStyle={'bold'}
              />
              <BasicTextStyleButton
                key={'italicStyleButton'}
                basicTextStyle={'italic'}
              />
              <BasicTextStyleButton
                key={'underlineStyleButton'}
                basicTextStyle={'underline'}
              />
              <BasicTextStyleButton
                key={'strikeStyleButton'}
                basicTextStyle={'strike'}
              />

              <CreateLinkButton key={'createLinkButton'} />
            </FormattingToolbar>
          )}
        />
        <SuggestionMenuController
          getItems={async (query) =>
            filterSuggestionItems(getCustomSlashMenuItems(editor), query)
          }
          triggerCharacter={'/'}
        />
      </BlockNoteView>
    </div>
  );
};
