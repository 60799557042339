import React from 'react';
import { Typography } from '@ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import numeral from 'numeral';
import { TooltipProps } from 'recharts';
import { MediaAnnouncement } from '@/apollo/generated';
import routes from '@/utils/routes';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export const ChartTooltip = ({
  active,
  formatKeysToPositive,
  label,
  labelFormatter,
  payload,
  primaryDataKey,
  showAnnouncements,
  showExtraSharePrices,
  valueFormatter,
}: TooltipProps<ValueType, NameType> & {
  formatKeysToPositive?: string[];
  primaryDataKey?: string;
  showAnnouncements?: boolean;
  showExtraSharePrices?: boolean;
  valueFormatter?: string;
}) => {
  const {
    query: { marketListingKey },
  } = useRouter();

  if (active && payload && payload.length) {
    const openSharePrice = payload.find((p) => p.dataKey === primaryDataKey)
      ?.payload?.open;

    const highSharePrice = payload.find((p) => p.dataKey === primaryDataKey)
      ?.payload?.high;

    const lowSharePrice = payload.find((p) => p.dataKey === primaryDataKey)
      ?.payload?.low;

    return (
      <div
        className="max-w-[225px] space-y-1 rounded-lg bg-gray-900 p-4"
        id="custom-tooltip"
      >
        <Typography className="text-white" variant="text-caption-bold">
          {labelFormatter ? labelFormatter(label, payload) : label}
        </Typography>
        {payload
          .filter((i) => typeof i.value !== 'object')
          .map((item) => (
            <div key={item.name} className="flex items-center gap-2">
              {item.color ? (
                <div
                  key={item.name}
                  className={clsx(
                    'w-3',
                    item.unit === 'square' ? 'h-3' : 'h-1'
                  )}
                  style={{ background: item.color }}
                />
              ) : null}
              <Typography className="text-white" variant="text-caption">
                {item.name}:{' '}
                {valueFormatter
                  ? numeral(
                      formatKeysToPositive?.includes(item.dataKey as string)
                        ? Math.abs(item.value as number)
                        : item.value
                    ).format(valueFormatter)
                  : item.value}
              </Typography>
            </div>
          ))}

        {showExtraSharePrices && openSharePrice ? (
          <div key="Open" className="flex items-center gap-2">
            <div
              key="Open"
              className="h-1 w-3"
              style={{ background: '#8b5cf6' }}
            />

            <Typography className="text-white" variant="text-caption">
              {`Open: ${openSharePrice}`}
            </Typography>
          </div>
        ) : null}

        {showExtraSharePrices && highSharePrice ? (
          <div key="High" className="flex items-center gap-2">
            <div
              key="High"
              className="h-1 w-3"
              style={{ background: '#12B76A' }}
            />

            <Typography className="text-white" variant="text-caption">
              {`High: ${highSharePrice}`}
            </Typography>
          </div>
        ) : null}

        {showExtraSharePrices && lowSharePrice ? (
          <div key="Low" className="flex items-center gap-2">
            <div
              key="Low"
              className="h-1 w-3"
              style={{ background: '#F63D68' }}
            />

            <Typography className="text-white" variant="text-caption">
              {`Low: ${lowSharePrice}`}
            </Typography>
          </div>
        ) : null}

        {showAnnouncements &&
        marketListingKey &&
        payload.find((p) => p.dataKey === primaryDataKey)?.payload
          ?.announcements?.length ? (
          <>
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full bg-white" />
              <Typography className="text-white" variant="text-caption">
                Announcements:
              </Typography>
            </div>
            <div className="ml-1 list-inside list-disc marker:text-white">
              {payload
                .find((p) => p.dataKey === primaryDataKey)
                ?.payload?.announcements?.map((a: MediaAnnouncement) => (
                  <Link
                    key={a.id}
                    className="group inline"
                    href={routes.engagement.interactiveMedia.announcement.href(
                      marketListingKey as string,
                      a.id
                    )}
                    style={{ pointerEvents: 'all' }}
                  >
                    <li>
                      <Typography
                        className="inline text-white group-hover:cursor-pointer group-hover:underline"
                        variant="text-hyperlink-caption"
                      >
                        {a.header}
                      </Typography>
                    </li>
                  </Link>
                ))}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
