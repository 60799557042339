// DS V2
export const AISummaryIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 30 20"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="m2.1262 5.8823c0.09494 0 0.17837 0.06155 0.20445 0.15082l0.23054 0.78914c0.10091 0.3454 0.37698 0.6154 0.73015 0.71408l0.8069 0.22547c0.09128 0.02551 0.15421 0.1071 0.15421 0.19994 0 0.09285-0.06293 0.17444-0.15421 0.19995l-0.8069 0.22547c-0.35317 0.09868-0.62924 0.36868-0.73015 0.71408l-0.23054 0.78914c-0.02608 0.08927-0.10951 0.15079-0.20445 0.15079-0.09493 0-0.17836-0.06152-0.20444-0.15079l-0.23054-0.78914c-0.10091-0.3454-0.37698-0.6154-0.73016-0.71408l-0.80689-0.22547c-0.09128-0.02551-0.15421-0.1071-0.15421-0.19995 0-0.09284 0.062932-0.17443 0.15421-0.19994l0.80689-0.22547c0.35318-0.09868 0.62924-0.36868 0.73016-0.71408l0.23054-0.78914c0.02608-0.08927 0.10951-0.15082 0.20444-0.15082z"
        fill="url(#d)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m5.2941 0c0.13132 0 0.24672 0.087052 0.2828 0.21332l0.3189 1.1162c0.13958 0.48854 0.52147 0.87042 1.01 1.01l1.1162 0.31891c0.12626 0.03607 0.21332 0.15148 0.21332 0.2828 0 0.13131-0.08706 0.24672-0.21332 0.2828l-1.1162 0.3189c-0.48855 0.13958-0.87043 0.52146-1.01 1.01l-0.3189 1.1162c-0.03608 0.12626-0.15148 0.21331-0.2828 0.21331s-0.24673-0.08705-0.2828-0.21331l-0.3189-1.1162c-0.13959-0.48854-0.52147-0.87042-1.01-1.01l-1.1162-0.3189c-0.12626-0.03608-0.21332-0.15149-0.21332-0.2828 0-0.13132 0.08706-0.24673 0.21332-0.2828l1.1162-0.31891c0.48854-0.13958 0.87042-0.52146 1.01-1.01l0.3189-1.1162c0.03607-0.12626 0.15148-0.21332 0.2828-0.21332z"
        fill="url(#c)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m25.294 14.118c0.1313 0 0.2467 0.087 0.2828 0.2133l0.3189 1.1161c0.1396 0.4886 0.5215 0.8705 1.01 1.0101l1.1162 0.3189c0.1262 0.036 0.2133 0.1514 0.2133 0.2828 0 0.1313-0.0871 0.2467-0.2133 0.2828l-1.1162 0.3189c-0.4885 0.1395-0.8704 0.5214-1.01 1.01l-0.3189 1.1161c-0.0361 0.1263-0.1515 0.2133-0.2828 0.2133s-0.2467-0.087-0.2828-0.2133l-0.3189-1.1161c-0.1396-0.4886-0.5214-0.8705-1.01-1.01l-1.1161-0.3189c-0.1263-0.0361-0.2134-0.1515-0.2134-0.2828 0-0.1314 0.0871-0.2468 0.2134-0.2828l1.1161-0.3189c0.4886-0.1396 0.8704-0.5215 1.01-1.0101l0.3189-1.1161c0.0361-0.1263 0.1515-0.2133 0.2828-0.2133z"
        fill="url(#b)"
        fillRule="evenodd"
      />
      <path
        d="m8.2353 17.647h7.0588v-1.9608h-7.0588v1.9608zm0-6.8627v1.9608h14.118v-1.9608h-14.118zm0-4.902v1.9608h21.177v-1.9608h-21.177z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="d"
          x1=".243"
          x2="3.9503"
          y1="5.9714"
          y2="10.005"
        >
          <stop offset=".03" stopColor="#8325FF" />
          <stop offset=".875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="c"
          x1="2.6891"
          x2="7.9412"
          y1=".12605"
          y2="5.7143"
        >
          <stop offset=".03" stopColor="#8325FF" />
          <stop offset=".875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="b"
          x1="22.689"
          x2="27.941"
          y1="14.244"
          y2="19.832"
        >
          <stop offset=".03" stopColor="#8325FF" />
          <stop offset=".875" stopColor="#7FC6F4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="a"
          x1="9.4454"
          x2="18.081"
          y1="6.1344"
          y2="22.674"
        >
          <stop offset=".03" stopColor="#8325FF" />
          <stop offset=".875" stopColor="#7FC6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AISummaryIcon;
