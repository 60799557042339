// DS V2
import React from 'react';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  heightClasses?: string;
  id?: string;
}

export const ChartWrapper: React.FC<Props> = ({
  children,
  heightClasses,
  id,
}) => {
  return (
    <div
      className={clsx(
        'relative w-full overflow-hidden lg:overflow-visible',
        heightClasses ? heightClasses : 'h-[500px] min-h-[500px]'
      )}
      id={id}
    >
      <div className="absolute left-0 top-0 h-full w-full overflow-x-auto overflow-y-hidden lg:overflow-visible">
        {children}
      </div>
    </div>
  );
};

export default ChartWrapper;
