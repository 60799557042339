import {
  ComponentType,
  MouseEventHandler,
  ReactElement,
  SVGProps,
} from 'react';
import clsx from 'clsx';

type ButtonGroupItem = {
  LeadingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  disabled?: boolean;
  hiddenLabel?: boolean;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
};

export interface ButtonGroupProps {
  items: ButtonGroupItem[];
}

export const ButtonGroup: ComponentType<ButtonGroupProps> = ({ items }) => {
  return (
    <div className="shadow-xs flex justify-evenly divide-x divide-gray-300 overflow-hidden rounded-lg border border-gray-300">
      {items.map((item) => (
        <button
          key={item.label}
          className={clsx(
            'group flex w-full items-center justify-center gap-2 py-2.5 font-sans text-sm font-medium leading-5 tracking-normal transition duration-200 ease-linear',
            item.hiddenLabel ? 'px-3' : 'px-4',
            item.selected
              ? 'bg-amplify-green-700 border-amplify-green-700 [&:not(:disabled)]:hover:bg-amplify-green-600 text-white disabled:bg-gray-50 disabled:text-gray-300 [&:not(:disabled)]:hover:text-white'
              : 'bg-white text-gray-700 hover:bg-gray-50 focus:bg-gray-50 disabled:bg-white disabled:text-gray-300'
          )}
          disabled={item.disabled}
          type="button"
          onClick={item.onClick}
        >
          {!!item.LeadingIcon && (
            <item.LeadingIcon
              className={clsx(
                'h-5 w-5 group-hover:text-gray-800 group-disabled:text-gray-300',
                item.selected ? 'text-gray-800' : 'text-gray-500'
              )}
            />
          )}
          {item.hiddenLabel ? null : <span>{item.label}</span>}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
