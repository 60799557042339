import React, { ReactNode } from 'react';
import { Typography } from '@ds';
import { QuestionMarkCircleIcon } from '@heroicons/react-v2/24/outline';
import clsx from 'clsx';
import { omit } from 'lodash';
import { ITooltip, Tooltip as ReactTooltip, PlacesType } from 'react-tooltip';

interface Props {
  children?: ReactNode;
  content?: string | ReactNode;
  customIcon?: React.ReactNode;
  description?: string;
  iconClassName?: string;
  place?: PlacesType;
  testId?: string;
  title?: string;
}

export type TooltipProps = Omit<ITooltip, 'content' | 'anchorSelect'> & Props;

export const Tooltip: React.ComponentType<TooltipProps> = (props) => {
  const {
    children,
    content,
    customIcon,
    description,
    iconClassName,
    id,
    style,
    testId,
    title,
  } = props;

  const finalContent = title || description || content;
  const interimId = id
    ? id
    : content
    ? content.toString()
    : description
    ? description
    : title
    ? title
    : '';

  const finalId = interimId.replace(/\W/g, '-');

  const finalProps = omit(props, 'content');

  const target = (
    <div className="cursor-pointer">
      {customIcon ? (
        <div
          className={clsx(
            'h-4 w-4',
            'text-gray-700',
            iconClassName && iconClassName
          )}
        >
          {customIcon}
        </div>
      ) : (
        <QuestionMarkCircleIcon
          className={clsx(
            'h-4 w-4',
            'text-gray-700',
            iconClassName && iconClassName
          )}
        />
      )}
    </div>
  );

  return typeof finalContent === 'string' ? (
    <div className="react-tooltip-wrapper" data-test={testId}>
      <div id={finalId}>{children || target}</div>
      <ReactTooltip
        anchorSelect={`#${finalId}`}
        className="content"
        style={style}
        {...finalProps}
      >
        <Typography className="text-white" variant="text-caption">
          {finalContent}
        </Typography>
      </ReactTooltip>
    </div>
  ) : (
    <div className="react-tooltip-wrapper" data-test={testId}>
      <div id={finalId}>{children || target}</div>
      <ReactTooltip
        anchorSelect={`#${finalId}`}
        className="z-tooltip shadow-lg"
        style={style}
        {...finalProps}
      >
        <div className="z-tooltip">{finalContent}</div>
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
