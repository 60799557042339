export const ViewFinderIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 2.5C3.61929 2.5 2.5 3.61929 2.5 5V6.25C2.5 6.59518 2.77982 6.875 3.125 6.875C3.47018 6.875 3.75 6.59518 3.75 6.25V5C3.75 4.30964 4.30964 3.75 5 3.75H6.25C6.59518 3.75 6.875 3.47018 6.875 3.125C6.875 2.77982 6.59518 2.5 6.25 2.5H5Z"
        fill="#437E76"
      />
      <path
        d="M13.75 2.5C13.4048 2.5 13.125 2.77982 13.125 3.125C13.125 3.47018 13.4048 3.75 13.75 3.75H15C15.6904 3.75 16.25 4.30964 16.25 5V6.25C16.25 6.59518 16.5298 6.875 16.875 6.875C17.2202 6.875 17.5 6.59518 17.5 6.25V5C17.5 3.61929 16.3807 2.5 15 2.5H13.75Z"
        fill="#437E76"
      />
      <path
        d="M10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875Z"
        fill="#437E76"
      />
      <path
        d="M3.75 13.75C3.75 13.4048 3.47018 13.125 3.125 13.125C2.77982 13.125 2.5 13.4048 2.5 13.75V15C2.5 16.3807 3.61929 17.5 5 17.5H6.25C6.59518 17.5 6.875 17.2202 6.875 16.875C6.875 16.5298 6.59518 16.25 6.25 16.25H5C4.30964 16.25 3.75 15.6904 3.75 15V13.75Z"
        fill="#437E76"
      />
      <path
        d="M17.5 13.75C17.5 13.4048 17.2202 13.125 16.875 13.125C16.5298 13.125 16.25 13.4048 16.25 13.75V15C16.25 15.6904 15.6904 16.25 15 16.25H13.75C13.4048 16.25 13.125 16.5298 13.125 16.875C13.125 17.2202 13.4048 17.5 13.75 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V13.75Z"
        fill="#437E76"
      />
    </svg>
  );
};
