import React from 'react';
import { Typography } from '@ds';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const ChartError: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'flex h-[500px] items-center justify-center gap-2',
        className ? className : ''
      )}
    >
      <ExclamationCircleIcon className="h-4 w-4" />
      <Typography>
        Something went wrong, please try again or contact InvestorHub.
      </Typography>
    </div>
  );
};

export default ChartError;
