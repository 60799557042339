// DS V2
export const FormatUnderlineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.25 15V16.5H3.75V15H14.25ZM12 9.91125C11.9753 10.4062 11.8284 10.8874 11.5723 11.3117C11.3162 11.7361 10.9591 12.0904 10.5326 12.3429C10.1062 12.5955 9.62388 12.7385 9.12871 12.7592C8.63353 12.7798 8.14096 12.6775 7.695 12.4613C7.18098 12.2389 6.74505 11.8681 6.44308 11.3964C6.1411 10.9247 5.98682 10.3737 6 9.81375V3.75375H4.5V9.91125C4.52537 10.6173 4.71664 11.3075 5.05831 11.9259C5.39999 12.5443 5.88247 13.0735 6.4667 13.4708C7.05094 13.8681 7.7205 14.1222 8.42121 14.2126C9.12191 14.3031 9.83406 14.2272 10.5 13.9913C11.3858 13.696 12.1544 13.1259 12.6939 12.3638C13.2335 11.6017 13.5158 10.6874 13.5 9.75375V3.75375H12V9.91125ZM12 3.75H13.5H12ZM6 3.75H4.5H6Z" />
    </svg>
  );
};

export default FormatUnderlineIcon;
