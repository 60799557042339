import { ComponentType, CSSProperties } from 'react';
import clsx from 'clsx';

export interface TextSkeletonProps {
  className?: string;
  style?: CSSProperties;
  variant: 'body' | 'display' | 'heading';
}

export const TextSkeleton: ComponentType<TextSkeletonProps> = ({
  className,
  style,
  variant,
}) => {
  return (
    <div
      className={clsx(
        'animate-pulse rounded-lg bg-gray-100',
        variant === 'body' && 'h-6',
        variant === 'display' && 'h-9',
        variant === 'heading' && 'h-7',
        className
      )}
      style={style}
    />
  );
};

export default TextSkeleton;
