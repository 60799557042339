// DS V2
import { createContext, useContext, useRef, useState } from 'react';
import { Toast, ToastProps } from '@ds';
import clsx from 'clsx';
import { formatError } from '@/utils/error-helpers';

interface AlertContext {
  closeAlert(): void;
  formatAndShowError(error: unknown): void;
  showAlert(args: ToastProps): void;
  showGenericError(): void;
}

const AlertContext = createContext<AlertContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeAlert() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  formatAndShowError() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showAlert() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showGenericError() {},
});

export const AlertProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [args, setArgs] = useState<ToastProps>({
    description: '',
    variant: 'success',
  });
  const [isVisible, setVisible] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();

  function closeAlert() {
    setVisible(false);
  }

  function formatAndShowError(error: unknown) {
    showAlert({
      description: formatError(error),
      variant: 'error',
    });
  }

  async function showAlert(_args: ToastProps) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isVisible) {
      setVisible(false);

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    setArgs(_args);
    setVisible(true);

    timeoutRef.current = setTimeout(() => {
      setVisible(false);
    }, 5000);
  }

  function showGenericError() {
    showAlert({
      description: 'There was an issue with processing your request.',
      variant: 'error',
    });
  }

  return (
    <AlertContext.Provider
      value={{ closeAlert, formatAndShowError, showAlert, showGenericError }}
    >
      {children}
      <div
        className={clsx(
          'fixed inset-x-4 bottom-4 z-[9999] transition duration-500 sm:mx-auto sm:max-w-[480px]',
          isVisible ? 'translate-y-0' : 'translate-y-96'
        )}
      >
        <Toast {...args} />
      </div>
    </AlertContext.Provider>
  );
};

export function useAlert() {
  return useContext(AlertContext);
}
