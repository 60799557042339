// DS V2
import { useCallback, useState } from 'react';
import analytics from '@analytics';
import Button from '@/components/ui/button';
import Square2Stack from '@/components/ui/icons/square-2-stack';
import Typography from '@/components/ui/typography';
import { copyToClipboard } from '@/utils/common-helpers';

interface Props {
  onCopyTrackingEvent?: string;
  url: string;
}

export const CopyLink: React.FC<Props> = ({ onCopyTrackingEvent, url }) => {
  const [isCopied, setCopied] = useState(false);

  const onCopy = useCallback(async () => {
    if (onCopyTrackingEvent) {
      analytics.track(onCopyTrackingEvent);
    }
    await copyToClipboard(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [url, onCopyTrackingEvent]);

  return (
    <div className="mt-1 flex h-10 items-center justify-between gap-3 rounded-lg border pl-2 pr-3">
      <Typography
        className="overflow-auto whitespace-nowrap text-gray-500"
        variant="text-body-md"
      >
        {url}
      </Typography>
      <Button
        LeadingIcon={
          isCopied
            ? undefined
            : () => <Square2Stack className="h-4 w-4 text-gray-700" />
        }
        variant="link-gray"
        onClick={isCopied ? undefined : onCopy}
      >
        {isCopied ? 'Copied' : 'Copy'}
      </Button>
    </div>
  );
};

export default CopyLink;
