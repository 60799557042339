// DS V2
export const ArrowUpIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.7813 2.3748C12.4003 1.8998 11.6003 1.8998 11.2193 2.3748L3.21927 12.3748C3.10191 12.522 3.02845 12.6993 3.00731 12.8864C2.98617 13.0735 3.01822 13.2627 3.09977 13.4324C3.18132 13.6021 3.30908 13.7454 3.46836 13.8457C3.62765 13.9461 3.81201 13.9995 4.00027 13.9998H8.00027V20.9998C8.00027 21.265 8.10563 21.5194 8.29317 21.7069C8.4807 21.8944 8.73506 21.9998 9.00027 21.9998H15.0003C15.2655 21.9998 15.5198 21.8944 15.7074 21.7069C15.8949 21.5194 16.0003 21.265 16.0003 20.9998V13.9998H20.0003C20.1885 13.9995 20.3729 13.9461 20.5322 13.8457C20.6915 13.7454 20.8192 13.6021 20.9008 13.4324C20.9823 13.2627 21.0144 13.0735 20.9932 12.8864C20.9721 12.6993 20.8986 12.522 20.7813 12.3748L12.7813 2.3748ZM15.0003 11.9998H14.0003V19.9998H10.0003V11.9998H6.08127L12.0003 4.6008L17.9193 11.9998H15.0003Z" />
    </svg>
  );
};

export default ArrowUpIcon;
