// DS V2
export const TextIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3570_40)">
        <path
          d="M0.585938 4.4623C0.909531 4.4623 1.17188 4.19996 1.17188 3.87637V1.17188H3.87637C4.19996 1.17188 4.4623 0.909531 4.4623 0.585938C4.4623 0.262344 4.19996 0 3.87637 0H0.585938C0.262344 0 0 0.262344 0 0.585938V3.87637C0 4.2 0.262344 4.4623 0.585938 4.4623Z"
          fill="currentColor"
        />
        <path
          d="M19.4141 0H16.1237C15.8001 0 15.5377 0.262344 15.5377 0.585938C15.5377 0.909531 15.8001 1.17188 16.1237 1.17188H18.8281V3.87637C18.8281 4.19996 19.0905 4.4623 19.4141 4.4623C19.7377 4.4623 20 4.19996 20 3.87637V0.585938C20 0.262344 19.7377 0 19.4141 0Z"
          fill="currentColor"
        />
        <path
          d="M19.4141 15.5377C19.0905 15.5377 18.8281 15.8 18.8281 16.1236V18.8281H16.1237C15.8001 18.8281 15.5377 19.0905 15.5377 19.4141C15.5377 19.7376 15.8001 20 16.1237 20H19.4141C19.7377 20 20 19.7376 20 19.4141V16.1236C20 15.8 19.7377 15.5377 19.4141 15.5377Z"
          fill="currentColor"
        />
        <path
          d="M3.87637 18.8281H1.17188V16.1236C1.17188 15.8 0.909531 15.5377 0.585938 15.5377C0.262344 15.5377 0 15.8 0 16.1236V19.4141C0 19.7376 0.262344 20 0.585938 20H3.87637C4.19996 20 4.4623 19.7376 4.4623 19.4141C4.4623 19.0905 4.2 18.8281 3.87637 18.8281Z"
          fill="currentColor"
        />
        <path
          d="M16.393 7.47578V3.77538C16.393 3.59179 16.2442 3.44296 16.0602 3.44296H10H3.93987C3.75588 3.44296 3.60706 3.59179 3.60706 3.77538V7.47578C3.60706 7.65976 3.75588 7.80859 3.93987 7.80859H5.70706C5.89065 7.80859 6.03948 7.65976 6.03948 7.47578V6.2082C6.03948 6.02421 6.1887 5.87538 6.37229 5.87538H8.45081C8.63479 5.87538 8.78362 6.02421 8.78362 6.2082V15.3543C8.78362 15.5383 8.63479 15.6871 8.45081 15.6871H7.54182C7.35784 15.6871 7.20901 15.8359 7.20901 16.0199V17.7871C7.20901 17.9707 7.35784 18.1195 7.54182 18.1195H10H12.4582C12.6422 18.1195 12.791 17.9707 12.791 17.7871V16.0199C12.791 15.8359 12.6422 15.6871 12.4582 15.6871H11.5492C11.3653 15.6871 11.2164 15.5383 11.2164 15.3543V6.2082C11.2164 6.02421 11.3653 5.87538 11.5492 5.87538H13.6278C13.8114 5.87538 13.9606 6.02421 13.9606 6.2082V7.47578C13.9606 7.65976 14.1094 7.80859 14.293 7.80859H16.0602C16.2442 7.80859 16.393 7.65976 16.393 7.47578Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3570_40">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextIcon;
