// DS V2
import { ComponentType, MouseEventHandler } from 'react';
import Button from '@/components/ui/button';
import Typography from '@/components/ui/typography';

export interface LoadMorePaginationProps {
  fetchedResults: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  totalResults: number;
}

export const LoadMorePagination: ComponentType<LoadMorePaginationProps> = ({
  fetchedResults,
  onClick,
  totalResults,
}) => {
  return (
    <div className="flex flex-col items-center gap-4 border-t border-t-gray-200 pb-4 pt-3 sm:gap-2 sm:px-6">
      <Typography className="text-gray-700" variant="text-body-sm">
        {'Showing '}
        {fetchedResults < totalResults && (
          <span className="font-semibold">{`1 to ${fetchedResults.toLocaleString()} of `}</span>
        )}
        <span className="font-semibold">{totalResults.toLocaleString()}</span>
        {' results'}
      </Typography>

      {fetchedResults < totalResults && (
        <Button size="sm" variant="secondary-gray" onClick={onClick}>
          Load more
        </Button>
      )}
    </div>
  );
};

export default LoadMorePagination;
