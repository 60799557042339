import React from 'react';
import { PlusIcon } from '@heroicons/react/outline';

interface EmptyImageThumbnailProps {
  onClick: () => void;
}

export const EmptyImageThumbnail: React.ComponentType<
  EmptyImageThumbnailProps
> = ({ onClick }) => {
  return (
    <div
      className="flex h-20 w-20 cursor-pointer items-center justify-center rounded border border-dashed border-gray-300"
      onClick={onClick}
    >
      <PlusIcon className="h-6 w-6 text-gray-400" />
    </div>
  );
};
