// DS V2
import React from 'react';

export const WarningIcon = () => {
  return (
    <svg
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#FEF0C7" height="48" rx="24" width="48" x="4" y="4" />
      <path
        d="M18.6965 32.1257L18.0469 31.7509L18.6965 32.1257ZM37.303 32.1257L37.9527 31.7509L37.303 32.1257ZM26.0509 19.3782L26.7005 19.753L26.0509 19.3782ZM29.9487 19.3782L30.5983 19.0034L29.9487 19.3782ZM28.7498 25.0001C28.7498 24.5859 28.414 24.2501 27.9998 24.2501C27.5856 24.2501 27.2498 24.5859 27.2498 25.0001H28.7498ZM27.2498 28.7501C27.2498 29.1643 27.5856 29.5001 27.9998 29.5001C28.414 29.5001 28.7498 29.1643 28.7498 28.7501H27.2498ZM27.9998 31.7501V31.0001C27.5856 31.0001 27.2498 31.3359 27.2498 31.7501H27.9998ZM28.0073 31.7501H28.7573C28.7573 31.3359 28.4215 31.0001 28.0073 31.0001V31.7501ZM28.0073 31.7576V32.5076C28.4215 32.5076 28.7573 32.1718 28.7573 31.7576H28.0073ZM27.9998 31.7576H27.2498C27.2498 32.1718 27.5856 32.5076 27.9998 32.5076V31.7576ZM18.0469 31.7509C16.893 33.7509 18.3365 36.2501 20.6454 36.2501V34.7501C19.491 34.7501 18.7692 33.5005 19.3462 32.5005L18.0469 31.7509ZM36.6534 32.5005C37.2303 33.5005 36.5086 34.7501 35.3541 34.7501V36.2501C37.6631 36.2501 39.1065 33.7509 37.9527 31.7509L36.6534 32.5005ZM26.7005 19.753C27.2777 18.7524 28.7218 18.7524 29.299 19.753L30.5983 19.0034C29.4438 17.0023 26.5557 17.0023 25.4012 19.0034L26.7005 19.753ZM27.2498 25.0001V28.7501H28.7498V25.0001H27.2498ZM27.9998 32.5001H28.0073V31.0001H27.9998V32.5001ZM27.2573 31.7501V31.7576H28.7573V31.7501H27.2573ZM28.0073 31.0076H27.9998V32.5076H28.0073V31.0076ZM28.7498 31.7576V31.7501H27.2498V31.7576H28.7498ZM19.3462 32.5005L26.7005 19.753L25.4012 19.0034L18.0469 31.7509L19.3462 32.5005ZM29.299 19.753L36.6534 32.5005L37.9527 31.7509L30.5983 19.0034L29.299 19.753ZM35.3541 34.7501H20.6454V36.2501H35.3541V34.7501Z"
        fill="#DC6803"
      />
      <rect
        height="48"
        rx="24"
        stroke="#FFFAEB"
        strokeWidth="8"
        width="48"
        x="4"
        y="4"
      />
    </svg>
  );
};
