import { forwardRef } from 'react';
import { ContentBlock, ContentState, SelectionState } from 'draft-js';

interface VideoPluginTheme {
  iframe: string;
  iframeContainer: string;
  invalidVideoSrc: string;
  video: string;
}

interface Props {
  //removed props
  block: ContentBlock;
  blockProps: { src: string };
  blockStyleFn: unknown;
  className?: string;

  contentState: ContentState;
  customStyleFn: unknown;
  customStyleMap: unknown;
  decorator: unknown;
  forceSelection: unknown;
  offsetKey: string;
  preventScroll?: unknown;
  selection: SelectionState;
  style?: React.CSSProperties;
  theme: VideoPluginTheme;
  tree: unknown;
}

const Video = forwardRef<HTMLVideoElement, Props>(
  ({ blockProps, className = '', style, theme, ...otherProps }, ref) => {
    const {
      block,
      blockStyleFn,
      contentState,
      customStyleFn,
      customStyleMap,
      decorator,
      forceSelection,
      offsetKey,
      preventScroll,
      selection,
      tree,
      ...elementProps
    } = otherProps;
    return (
      <video
        ref={ref}
        className={theme.video}
        src={blockProps.src}
        style={style}
        {...elementProps}
        controls
      />
    );
  }
);

Video.displayName = 'Video';

export default Video;
