// DS V2

import React from 'react';

export const ParagraphAlignLeftIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 18H9V16H3V18ZM3 11V13H15V11H3ZM3 6V8H21V6H3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ParagraphAlignLeftIcon;
