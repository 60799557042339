import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { TextInput, Typography } from '@ds';
import { Libraries } from '@googlemaps/js-api-loader';
import { Combobox } from '@headlessui/react';
import { useJsApiLoader } from '@react-google-maps/api';
import clsx from 'clsx';
import { useAsyncDebounce } from 'react-table';

const libraries: Libraries = ['places'];

type SetAddress = (
  address: AmplifyAddress
) => void | Dispatch<SetStateAction<AmplifyAddress>>;

interface Props {
  address?: AmplifyAddress;
  helperText?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  setAddress?: SetAddress;
}

type SelectedAddress = {
  description?: string;
  placeId?: string;
};

export const AddressAutocomplete: React.ComponentType<Props> = ({
  address,
  helperText,
  id,
  label,
  placeholder,
  setAddress,
}) => {
  const [placesService, setPlacesService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [detailsService, setDetailsService] =
    useState<google.maps.places.PlacesService | null>(null);
  const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [addressPredictions, setAddressesPredictions] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([]);

  const placeField = useRef<HTMLInputElement | null>(null);

  const [selectedAddress, setSelectedAddress] =
    useState<SelectedAddress | null>({
      description: address?.formattedAddress || '',
      placeId: '',
    });
  const [query, setQuery] = useState(address?.formattedAddress || '');

  const onChange = useAsyncDebounce((value) => {
    setQuery(value);
  }, 300);

  const displaySuggestions = function (
    predictions: google.maps.places.QueryAutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ) {
    if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
      return;
    }

    setAddressesPredictions(predictions);
  };

  useEffect(() => {
    if (placesService && query.length) {
      // Only get result if query is different than original string passed in
      if (query != address?.formattedAddress) {
        placesService.getQueryPredictions(
          {
            input: query,
            location: new google.maps.LatLng(-26.866306, 134.6623466),
            radius: 2000,
          },
          displaySuggestions
        );
      }
    }
  }, [query, placesService, address]);

  const convertMapsPlaceResultToAmplify = (
    components: google.maps.places.PlaceResult['address_components']
  ): AmplifyAddress['components'] => {
    if (!components) return undefined;
    const r = {
      addressCity: '',
      addressCountry: '',
      addressLineOne: '',
      addressLineTwo: '',
      addressPostcode: '',
      addressState: '',
    };
    components.forEach((c) => {
      if (c.types.includes('postal_code')) {
        r.addressPostcode = c.short_name;
      }
      if (c.types.includes('country')) {
        r.addressCountry = c.long_name;
      }
      if (c.types.includes('administrative_area_level_1')) {
        r.addressState = c.short_name;
      }
      if (c.types.includes('locality')) {
        r.addressCity = c.short_name;
      }
      if (c.types.includes('subpremise')) {
        r.addressLineOne = `${c.short_name}/`;
      }
      if (c.types.includes('street_number')) {
        r.addressLineOne = `${r.addressLineOne}${c.short_name} `;
      }
      if (c.types.includes('route')) {
        r.addressLineOne = `${r.addressLineOne}${c.short_name}`;
      }
    });
    return r;
  };

  useEffect(() => {
    if (isLoaded && !placesService) {
      setPlacesService(new google.maps.places.AutocompleteService());
    }
    if (isLoaded && !detailsService && placeField.current) {
      setDetailsService(
        new google.maps.places.PlacesService(placeField.current)
      );
    }
  }, [placesService, isLoaded, detailsService]);

  useEffect(() => {
    const setPlaceResult = function (
      result: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      if (setAddress && !!result) {
        setAddress({
          components: convertMapsPlaceResultToAmplify(
            result.address_components
          ),
          formattedAddress: result.formatted_address,
        });
      }
    };

    if (detailsService && !!selectedAddress?.placeId?.length) {
      detailsService.getDetails(
        { placeId: selectedAddress.placeId || '' },
        setPlaceResult
      );
    }
  }, [selectedAddress, detailsService, setAddress]);

  return (
    <div className="space-y-1.5">
      {!!label && (
        <label className="block" htmlFor={id}>
          <Typography
            className="text-gray-700"
            component="span"
            variant="text-label-sm"
          >
            {label}
          </Typography>
        </label>
      )}
      <Combobox as="div" value={selectedAddress} onChange={setSelectedAddress}>
        <input ref={placeField} id={id} name="place-field" type="hidden" />
        <div className="relative mt-1">
          <Combobox.Input
            as={TextInput}
            displayValue={(
              selectedAddress?: google.maps.places.AutocompletePrediction
            ) => (selectedAddress ? selectedAddress.description : '')}
            helperText={helperText}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
          />
          <Combobox.Options className="absolute top-14 z-10 max-h-[144px] w-full overflow-auto border bg-white focus:outline-none">
            {addressPredictions.map((address, i) => (
              <Combobox.Option
                key={`${address.place_id}-${i}`}
                className={({ active }) =>
                  clsx(
                    'relative min-h-[48px] cursor-default select-none px-4 py-3 text-gray-900',
                    active ? 'bg-amplify-green-50' : 'bg-white'
                  )
                }
                value={{
                  description: address.description,
                  placeId: address.place_id,
                }}
              >
                {address.description}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};
