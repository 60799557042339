// DS V2
import { ComponentType } from 'react';
import { Listbox } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Button from '@/components/ui/button';
import ButtonGroup from '@/components/ui/button-group';
import buttonStyles from '@/components/ui/button.module.css';
import Typography from '@/components/ui/typography';

export const ROWS_PER_PAGE = [10, 20, 30, 40, 50];
export const ROWS_PER_PAGE_NEW = [50, 40, 30, 20, 10];
export interface TablePaginationProps {
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onPageChange: (newSelectedPage: number) => void;
  rowsPerPage: number;
  selectedPage: number;
  selfPadding?: boolean;
  testId?: string;
  totalResults: number;
}

export const TablePagination: ComponentType<TablePaginationProps> = ({
  onChangeRowsPerPage,
  onPageChange,
  rowsPerPage,
  selectedPage,
  selfPadding = true,
  testId,
  totalResults,
}) => {
  const pageCount = Math.ceil(totalResults / rowsPerPage);

  const startAt = selectedPage > 1 ? (selectedPage - 1) * rowsPerPage + 1 : 1;

  const endAt = Math.min(selectedPage * rowsPerPage, totalResults);

  return (
    <>
      <div
        className={clsx(
          'hidden items-center justify-between gap-3 sm:flex sm:gap-6',
          selfPadding ? 'px-4 py-3 sm:px-6 sm:py-4' : ''
        )}
      >
        <div className="flex items-center gap-4">
          <Typography className="text-gray-500" variant="text-label-sm">
            Rows per page
          </Typography>
          <Listbox
            as="div"
            className="relative"
            value={rowsPerPage}
            onChange={(value: number) => {
              onPageChange(1);
              onChangeRowsPerPage(value);
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  className={clsx(
                    buttonStyles['base'],
                    buttonStyles['pad-sm'],
                    buttonStyles['secondary-gray'],
                    buttonStyles['title-sm']
                  )}
                >
                  {rowsPerPage}
                  <ChevronDownIcon
                    className={clsx(
                      'h-5 w-5 transition-all',
                      open && 'rotate-180'
                    )}
                  />
                </Listbox.Button>
                <Listbox.Options className="absolute inset-x-0 bottom-full z-40 mb-2 overflow-hidden rounded-md bg-white shadow-lg">
                  {ROWS_PER_PAGE_NEW.map((item) => (
                    <Listbox.Option
                      key={item}
                      className={clsx(
                        'flex cursor-pointer items-center justify-between gap-1 px-4 py-3',
                        item === rowsPerPage
                          ? 'bg-amplify-green-25'
                          : 'hover:bg-gray-50'
                      )}
                      value={item}
                    >
                      <Typography
                        className="text-gray-900"
                        variant="text-body-sm"
                      >
                        {item}
                      </Typography>
                      {item === rowsPerPage && (
                        <CheckIcon className="h-5 w-5 text-gray-900" />
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </>
            )}
          </Listbox>
        </div>
        <div className="flex items-center gap-4">
          <Typography className="text-gray-700" variant="text-body-sm">
            {'Displaying '}
            <span className="font-semibold">
              {totalResults ? startAt.toLocaleString() : '0'}
            </span>
            {' to '}
            <span className="font-semibold">{endAt.toLocaleString()}</span>
            {' of '}
            <span className="font-semibold" data-test={testId}>
              {totalResults.toLocaleString()}
            </span>
          </Typography>
          <ButtonGroup
            items={[
              {
                LeadingIcon: ArrowLeftIcon,
                disabled: selectedPage === 1,
                hiddenLabel: true,
                label: 'Back',
                onClick: () => onPageChange(selectedPage - 1),
              },
              {
                LeadingIcon: ArrowRightIcon,
                disabled: selectedPage === pageCount || totalResults === 0,
                hiddenLabel: true,
                label: 'Next',
                onClick: () => onPageChange(selectedPage + 1),
              },
            ]}
          />
        </div>
      </div>
      <div className="flex items-center justify-between px-4 py-3 sm:hidden">
        <Button
          MainIcon={ArrowLeftIcon}
          disabled={selectedPage === 1}
          size="sm"
          variant="secondary-gray"
          onClick={() => onPageChange(selectedPage - 1)}
        >
          Previous
        </Button>
        <Typography className="text-gray-700" variant="text-body-sm">
          {'Displaying '}
          <span className="font-semibold">
            {totalResults ? startAt.toLocaleString() : '0'}
          </span>
          {' to '}
          <span className="font-semibold" data-test={testId}>
            {endAt.toLocaleString()}
          </span>
          {' of '}
          <span className="font-semibold">{totalResults.toLocaleString()}</span>
        </Typography>
        <Button
          MainIcon={ArrowRightIcon}
          disabled={selectedPage === pageCount || totalResults === 0}
          size="sm"
          variant="secondary-gray"
          onClick={() => onPageChange(selectedPage + 1)}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default TablePagination;
