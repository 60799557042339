// DS V2
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import Typography from '@/components/ui/typography';

export interface SuccessModalProps {
  actionsDisabled?: boolean;
  description: React.ReactNode;
  icon?: React.ReactNode;
  isOpen: boolean;
  onPositiveActionClicked: () => void;
  positiveButtonTitle: string;
  title: string;
}

export const SuccessModal: React.ComponentType<SuccessModalProps> = ({
  actionsDisabled = false,
  description,
  icon,
  isOpen,
  onPositiveActionClicked,
  positiveButtonTitle,
  title,
}) => {
  return (
    <Modal className="max-w-sm" open={isOpen} onClose={onPositiveActionClicked}>
      {icon}
      <Typography
        className={icon ? 'mt-5 text-left text-gray-900' : undefined}
        variant="text-label-lg"
      >
        {title}
      </Typography>
      {description}
      <div className="mt-6 flex w-full flex-col gap-4 sm:mt-8 sm:flex-row-reverse sm:justify-center">
        <Button
          className="flex-1"
          disabled={actionsDisabled}
          variant="primary"
          onClick={onPositiveActionClicked}
        >
          {positiveButtonTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
