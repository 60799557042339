// DS V2
import { ComponentType, MouseEventHandler } from 'react';
import { Button, ButtonProps, Typography } from '@ds';
import { SearchIcon } from '@heroicons/react/outline';

export interface FilterEmptyStateProps {
  description?: string;
  onClickClearSearch?: MouseEventHandler<HTMLButtonElement>;
  onClickPrimaryCTA?: MouseEventHandler<HTMLButtonElement>;
  primaryCTA?: string;
  primaryCTAProps?: Pick<ButtonProps, 'LeadingIcon' | 'TrailingIcon'>;
}

export const FilterEmptyState: ComponentType<FilterEmptyStateProps> = ({
  description,
  onClickClearSearch,
  onClickPrimaryCTA,
  primaryCTA,
  primaryCTAProps,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-fresh-neon-100 border-fresh-neon-25 flex h-14 w-14 items-center justify-center rounded-full border-8">
        <SearchIcon className="text-fresh-neon-900 h-6 w-6" />
      </div>
      <Typography
        className="mt-2 text-center text-gray-900"
        variant="text-heading-sm"
      >
        No results found
      </Typography>
      <Typography
        className="mt-1 whitespace-pre-line text-center text-gray-500"
        variant="text-body-sm"
      >
        {description ??
          'Your search or filter did not match any results.\nPlease try again.'}
      </Typography>
      <div className="mt-6 flex flex-col gap-3 sm:flex-row-reverse sm:items-center sm:justify-center">
        {!!onClickPrimaryCTA && (
          <Button
            {...primaryCTAProps}
            className="w-full"
            size="sm"
            onClick={onClickPrimaryCTA}
          >
            {primaryCTA}
          </Button>
        )}
        {!!onClickClearSearch && (
          <Button
            className="w-full"
            size="sm"
            variant="secondary-gray"
            onClick={onClickClearSearch}
          >
            Clear search
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilterEmptyState;
