import { AlertProvider } from '@/contexts/alert-context';
import { ContactFreshProvider } from '@/contexts/contact-fresh-context';
import { CookieBannerProvider } from '@/contexts/cookie-banner-context';

const ContextProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <AlertProvider>
      <ContactFreshProvider>
        <CookieBannerProvider>{children}</CookieBannerProvider>
      </ContactFreshProvider>
    </AlertProvider>
  );
};

export default ContextProvider;
