// DS V2
export const FormatBoldIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.4375 8.85C11.8048 8.62638 12.1147 8.32006 12.3425 7.95547C12.5704 7.59087 12.71 7.17808 12.75 6.75C12.757 6.36291 12.6876 5.97825 12.5459 5.61797C12.4042 5.2577 12.1928 4.92888 11.924 4.65029C11.6552 4.3717 11.3341 4.14881 10.9791 3.99433C10.6241 3.83986 10.2421 3.75683 9.85505 3.75H4.98755V14.25H10.2375C10.6059 14.2461 10.9699 14.1696 11.3087 14.025C11.6475 13.8804 11.9546 13.6705 12.2122 13.4073C12.4699 13.144 12.6732 12.8326 12.8106 12.4907C12.9479 12.1489 13.0165 11.7834 13.0125 11.415V11.325C13.0128 10.8053 12.8647 10.2964 12.5857 9.85797C12.3067 9.41954 11.9084 9.06986 11.4375 8.85ZM6.48755 5.25H9.63755C9.95718 5.24011 10.2722 5.32821 10.5403 5.50249C10.8084 5.67677 11.0168 5.92888 11.1375 6.225C11.2597 6.62084 11.2202 7.04894 11.0277 7.41574C10.8351 7.78254 10.5052 8.05821 10.11 8.1825C9.95658 8.22747 9.79747 8.25021 9.63755 8.25H6.48755V5.25ZM9.93755 12.75H6.48755V9.75H9.93755C10.2572 9.74011 10.5722 9.82821 10.8403 10.0025C11.1084 10.1768 11.3168 10.4289 11.4375 10.725C11.5597 11.1208 11.5202 11.5489 11.3277 11.9157C11.1351 12.2825 10.8052 12.5582 10.41 12.6825C10.2566 12.7275 10.0975 12.7502 9.93755 12.75Z" />
    </svg>
  );
};

export default FormatBoldIcon;
