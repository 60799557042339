// DS V2
import React from 'react';

export const CheckCircleIcon = () => {
  return (
    <svg
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#D1FADF" height="48" rx="24" width="48" x="4" y="4" />
      <path
        d="M20.5 28.75L26.5 34.75L35.5 21.25"
        stroke="#039855"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect
        height="48"
        rx="24"
        stroke="#ECFDF3"
        strokeWidth="8"
        width="48"
        x="4"
        y="4"
      />
    </svg>
  );
};
