import { ComponentType, useMemo } from 'react';
import { Badge, Typography, Tooltip } from '@ds';
import {
  PlusIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import numeral from 'numeral';

interface StatCardProps {
  badgeNum?: number;
  error?: boolean;
  href?: string;
  icon: JSX.Element;
  loading?: boolean;
  onClickAction?: () => Promise<boolean>;
  percentageComparison?: boolean;
  statBadge?: JSX.Element; // Badge beside the statNum
  statNum: number;
  timePeriodText?: string;
  title: string;
  tooltipText?: string;
}

export const StatCard: ComponentType<StatCardProps> = ({
  badgeNum,
  error = false,
  href,
  icon,
  loading,
  onClickAction,
  percentageComparison,
  statBadge,
  statNum,
  timePeriodText,
  title,
  tooltipText,
}) => {
  const badgeBgColor = useMemo(() => {
    if (badgeNum && !error) {
      if (badgeNum > 0) return 'green';
      return 'red';
    }
    return 'gray';
  }, [badgeNum, error]);

  const id = `stat-card-${title.replace(/\W/g, '-')}`;

  const badgeLeadingIcon = useMemo(() => {
    if (badgeNum && !error) {
      if (badgeNum > 0)
        return <ArrowUpIcon className="h-2 w-2 text-green-500" />;
      return <ArrowDownIcon className="h-2 w-2 text-red-500" />;
    }
    return <PlusIcon className="h-2 w-2 text-gray-500" />;
  }, [badgeNum, error]);

  const renderContent = (loading?: boolean) => {
    if (loading) {
      return (
        <>
          <div className="flex flex-1 gap-4">
            <div className="h-12 w-12 animate-pulse rounded-full bg-gray-100" />
            <div className="flex-1 space-y-1">
              <div className="h-5 w-[65%] animate-pulse rounded-lg bg-gray-100" />
              <div className="h-7 w-[30%] animate-pulse rounded-lg bg-gray-100" />
              {(badgeNum || badgeNum === 0) && (
                <div className="h-5 w-[50%] animate-pulse rounded-lg bg-gray-100" />
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-1 gap-4">
          {icon}
          <div className="space-y-1">
            <div className="flex items-center gap-1">
              <Typography
                className="whitespace-nowrap text-gray-900"
                variant="text-body-sm"
              >
                {title}
              </Typography>
              {tooltipText && (
                <Tooltip content={tooltipText} id={`tooltip-${id}`} />
              )}
            </div>
            <div className="flex items-center space-x-2">
              <Typography className="text-gray-900" variant="text-heading-lg">
                {error ? 0 : numeral(statNum).format('0.[0]a')}
              </Typography>

              {statBadge && statBadge}
            </div>
            {(badgeNum || badgeNum === 0) && (
              <div className="flex items-center gap-1">
                <Badge
                  LeadingIcon={() => badgeLeadingIcon}
                  color={badgeBgColor}
                  size="xs"
                >
                  {error ? 0 : numeral(badgeNum).format('0.[0]a')}
                  {percentageComparison && '%'}
                </Badge>
                {timePeriodText && (
                  <Typography
                    className="lowercase text-gray-500"
                    variant="text-caption"
                  >
                    {timePeriodText}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
        {(onClickAction || href) && (
          <div>
            <ChevronRightIcon className="h-4 w-4 text-gray-900" />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={clsx(
        (onClickAction || href) &&
          'cursor-pointer hover:border-gray-300 hover:bg-gray-50 hover:shadow-md',
        'flex w-full flex-1 items-center justify-between rounded-lg border border-gray-200 bg-white p-6'
      )}
      onClick={onClickAction ?? undefined}
    >
      {href ? (
        <Link
          className="flex w-full flex-1 items-center justify-between"
          href={href}
        >
          {renderContent(loading)}
        </Link>
      ) : (
        renderContent(loading)
      )}
    </div>
  );
};
