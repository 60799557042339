import cookieParser from 'cookie';

const ATHENA_LOGROCKET_SESSION_URL_COOKIE = '_athena_logrocket_session_url';

const ATHENA_LOGROCKET_SESSION_URL_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions =
  {
    domain:
      process.env.NODE_ENV === 'production'
        ? '.investorhub.com'
        : '.localhost.com',
    path: '/',
    secure: process.env.NODE_ENV === 'production',
  };

export function setCookie(sessionURL: string) {
  document.cookie = cookieParser.serialize(
    ATHENA_LOGROCKET_SESSION_URL_COOKIE,
    sessionURL,
    ATHENA_LOGROCKET_SESSION_URL_COOKIE_OPTIONS
  );
}
