import { ComponentType, MouseEventHandler } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export interface ToastProps {
  description: string;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
  variant: 'success' | 'error';
}

export const Toast: ComponentType<ToastProps> = ({
  description,
  onDismiss,
  variant,
}) => {
  return (
    <div
      className={clsx(
        'relative flex items-center gap-3 rounded-md py-4 pl-4 shadow-md',
        onDismiss ? 'pr-12' : 'pr-4',
        variant === 'error' && 'bg-red-25',
        variant === 'success' && 'bg-green-25'
      )}
    >
      {variant === 'error' && (
        <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
      )}
      {variant === 'success' && (
        <CheckCircleIcon className="h-5 w-5 text-green-600" />
      )}
      <Typography
        className={clsx(
          variant === 'error' && 'text-red-700',
          variant === 'success' && 'text-green-800'
        )}
        variant="text-label-sm"
      >
        {description}
      </Typography>
      {!!onDismiss && (
        <button
          className="absolute right-4 top-4"
          type="button"
          onClick={onDismiss}
        >
          <XIcon
            className={clsx(
              'h-4 w-4',
              variant === 'error' && 'text-red-700',
              variant === 'success' && 'text-amplify-green-700'
            )}
          />
        </button>
      )}
    </div>
  );
};

export default Toast;
