// DS V2
import { ComponentType } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import Button from '@/components/ui/button';
import Typography from '@/components/ui/typography';
import styles from '@/components/ui/typography.module.css';

export interface PaginationProps {
  // Some layouts require the pagination to use the mobile view on desktop, i.e for narrow views like a sidebar
  forceMobile?: boolean;
  /**
   * This pagination assumes one based indexing on pages and will break if you use zero based indexing
   * e.g if you want the first page, selectedPage should be 1
   */
  onPageChange: (newSelectedPage: number) => void;
  rowsPerPage: number;
  /**
   * This pagination assumes one based indexing on pages and will break if you use zero based indexing
   * e.g if you want the first page, selectedPage should be 1
   */
  selectedPage: number;
  totalResults: number;
  variant?: 'card' | 'page';
}

/**
 * ** WARNING **
 *
 * This pagination component uses one based indexing. It will break if you use zero based indexing
 *
 * @example
 *
 * const pages = [{page: 1, content: "hi"}, {page: 2, content: "hello"}, {page: 3, content: "hey"}]
 *
 * // selectedPage = pages[0].page
 */
export const Pagination: ComponentType<PaginationProps> = ({
  forceMobile = false,
  onPageChange: onPageOneIndexed,
  rowsPerPage,
  selectedPage: selectedPageOneIndexed,
  totalResults,
  variant = 'page',
}) => {
  const pageCount = Math.max(1, Math.ceil(totalResults / rowsPerPage));

  // We are having to override the props passed as ReactPaginate uses zero based indexing, even when rendering pages with the value 1
  // We decided to handle in our component as our expected behaviour is one based indexing
  const selectedPage = selectedPageOneIndexed - 1;

  const onPageChange = (page: number) => onPageOneIndexed(page + 1);

  return (
    <>
      <div
        className={clsx(
          'flex items-center justify-between gap-3 border-t border-t-gray-200 px-4 pb-4 pt-3',
          forceMobile ? '' : 'sm:hidden'
        )}
      >
        <Button
          MainIcon={ArrowLeftIcon}
          disabled={selectedPage === 0}
          size="sm"
          variant="secondary-gray"
          onClick={() => onPageChange(selectedPage - 1)}
        >
          Previous
        </Button>
        <Typography className="text-gray-700" variant="text-body-sm">
          {'Page '}
          <span className="font-semibold">{selectedPage + 1}</span>
          {' of '}
          <span className="font-semibold">{pageCount}</span>
        </Typography>
        <Button
          MainIcon={ArrowRightIcon}
          disabled={selectedPage === pageCount - 1}
          size="sm"
          variant="secondary-gray"
          onClick={() => onPageChange(selectedPage + 1)}
        >
          Next
        </Button>
      </div>
      {variant === 'card' && (
        <div
          className={clsx(
            'hidden items-center justify-between gap-3 border-t border-t-gray-200 px-6 py-3 sm:px-0',
            forceMobile ? '' : 'sm:flex'
          )}
        >
          <Typography className="text-gray-700" variant="text-body-sm">
            {'Page '}
            <span className="font-semibold">{selectedPage + 1}</span>
            {' of '}
            <span className="font-semibold">{pageCount}</span>
          </Typography>
          <div className="flex items-center gap-3">
            <Button
              disabled={selectedPage === 0}
              size="sm"
              variant="secondary-gray"
              onClick={() => onPageChange(selectedPage - 1)}
            >
              Previous
            </Button>
            <Button
              disabled={selectedPage === pageCount - 1}
              size="sm"
              variant="secondary-gray"
              onClick={() => onPageChange(selectedPage + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      )}
      {variant === 'page' && (
        <div
          className={clsx(
            'hidden items-center gap-3 border-t border-t-gray-200 pb-4 pt-3',
            forceMobile ? '' : 'sm:flex'
          )}
        >
          <div className="min-w-0 flex-1">
            <Button
              LeadingIcon={ArrowLeftIcon}
              disabled={selectedPage === 0}
              size="sm"
              variant="tertiary-gray"
              onClick={() => onPageChange(selectedPage - 1)}
            >
              Previous
            </Button>
          </div>
          <ReactPaginate
            activeLinkClassName="text-gray-800 bg-gray-50"
            breakLinkClassName={clsx(
              'flex h-10 w-10 items-center justify-center rounded-lg text-gray-500 hover:bg-gray-50',
              styles['text-button-sm']
            )}
            containerClassName="flex"
            forcePage={selectedPage}
            nextClassName="hidden"
            pageCount={pageCount}
            pageLinkClassName={clsx(
              'flex h-10 w-10 items-center justify-center rounded-lg text-gray-500 hover:bg-gray-50',
              styles['text-button-sm']
            )}
            previousClassName="hidden"
            onPageChange={({ selected }) => onPageChange(selected)}
          />
          <div className="flex min-w-0 flex-1 justify-end">
            <Button
              TrailingIcon={ArrowRightIcon}
              disabled={selectedPage === pageCount - 1}
              size="sm"
              variant="tertiary-gray"
              onClick={() => onPageChange(selectedPage + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
