import React, { SVGProps } from 'react';
import { TickItem } from 'recharts/types/util/types';

export type TickProps = SVGProps<SVGTextElement> & {
  hideFirst?: boolean;
  hideLast?: boolean;
  index?: number;
  payload?: TickItem;
  tickFormatter?: (value: string | number, index: number) => string;
  visibleTicksCount?: number;
};
export const Tick = (props: TickProps) => {
  if (props.hideLast && props.index === (props.visibleTicksCount || 0) - 1)
    return null;
  if (props.hideFirst && props.index === 0) return null;
  const value =
    props.tickFormatter && props.payload
      ? props.tickFormatter(props.payload.value, props.index || 0)
      : '';
  return (
    <text
      className={props.className}
      color={props.color || '#6C737F'}
      fill={props.color || '#6C737F'}
      fontSize={props.fontSize || '12'}
      fontWeight={props.fontWeight || '400'}
      height={props.height}
      orientation={props.orientation}
      textAnchor={props.textAnchor}
      type={props.type}
      width={props.width}
      x={props.x}
      y={props.y}
    >
      <tspan
        dy={props.dy || '0.355em'}
        fontSize={props.fontSize || '12'}
        fontWeight={props.fontWeight || '400'}
        x={props.x}
      >
        {value}
      </tspan>
    </text>
  );
};

export default Tick;
