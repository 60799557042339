// DS V2
import { ChangeEventHandler } from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import TypographyStyles from '@/components/ui/typography.module.css';

export interface InvisibleTextInputProps {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  size?: 'text-display-md';
  value?: string;
}

export const InvisibleTextInput: React.ComponentType<
  InvisibleTextInputProps
> = ({ onChange, placeholder, size, value }) => {
  return (
    <TextareaAutosize
      className={clsx(
        'w-full resize-none overflow-hidden border-0 p-0 focus:ring-0',
        size === 'text-display-md' && TypographyStyles['text-display-md']
      )}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default InvisibleTextInput;
