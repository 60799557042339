// DS V2
export const FormatLinkIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.25 14.75H7.25C6.25544 14.75 5.30161 14.3549 4.59835 13.6517C3.89509 12.9484 3.5 11.9946 3.5 11C3.5 10.0054 3.89509 9.05161 4.59835 8.34835C5.30161 7.64509 6.25544 7.25 7.25 7.25H10.25V8.75H7.25C6.65326 8.75 6.08097 8.98705 5.65901 9.40901C5.23705 9.83097 5 10.4033 5 11C5 11.5967 5.23705 12.169 5.65901 12.591C6.08097 13.0129 6.65326 13.25 7.25 13.25H10.25V14.75ZM14.75 7.25H11.75V8.75H14.75C15.3467 8.75 15.919 8.98705 16.341 9.40901C16.7629 9.83097 17 10.4033 17 11C17 11.5967 16.7629 12.169 16.341 12.591C15.919 13.0129 15.3467 13.25 14.75 13.25H11.75V14.75H14.75C15.7446 14.75 16.6984 14.3549 17.4017 13.6517C18.1049 12.9484 18.5 11.9946 18.5 11C18.5 10.0054 18.1049 9.05161 17.4017 8.34835C16.6984 7.64509 15.7446 7.25 14.75 7.25V7.25ZM14 10.25H8V11.75H14V10.25Z" />
    </svg>
  );
};

export default FormatLinkIcon;
