// DS V2
import { ComponentType, MouseEventHandler } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export type PromptAction = {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export interface PromptProps {
  actions?: PromptAction[];
  description?: string;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  variant: 'info' | 'gray' | 'error' | 'warning' | 'success';
}

export const Prompt: ComponentType<PromptProps> = ({
  actions,
  description,
  onDismiss,
  title,
  variant,
}) => {
  return (
    <div
      className={clsx(
        'relative flex flex-col items-start gap-3 rounded-lg border p-4 sm:flex-row',
        onDismiss ? 'sm:pr-12' : 'sm:pr-4',
        variant === 'info' && 'bg-cyan-25 border-cyan-300',
        variant === 'gray' && 'bg-gray-25 border-gray-300',
        variant === 'error' && 'bg-red-25 border-red-300',
        variant === 'warning' && 'bg-amber-25 border-amber-300',
        variant === 'success' && 'bg-green-25 border-green-300'
      )}
    >
      <div className="h-5 w-5">
        {variant === 'info' && (
          <ExclamationCircleIcon className="h-5 w-5 text-cyan-600" />
        )}
        {variant === 'gray' && (
          <ExclamationCircleIcon className="h-5 w-5 text-gray-600" />
        )}
        {variant === 'error' && (
          <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
        )}
        {variant === 'warning' && (
          <ExclamationIcon className="h-5 w-5 text-amber-600" />
        )}
        {variant === 'success' && (
          <CheckCircleIcon className="h-5 w-5 text-green-600" />
        )}
      </div>
      <div className="min-w-0 flex-1">
        {title && (
          <Typography
            className={clsx(
              variant === 'info' && 'text-cyan-800',
              variant === 'gray' && 'text-gray-700',
              variant === 'error' && 'text-red-700',
              variant === 'warning' && 'text-amber-700',
              variant === 'success' && 'text-green-800'
            )}
            variant="text-label-sm"
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className={clsx(
              'whitespace-pre-wrap',
              variant === 'info' && 'text-cyan-700',
              variant === 'gray' && 'text-gray-600',
              variant === 'error' && 'text-red-600',
              variant === 'warning' && 'text-amber-600',
              variant === 'success' && 'text-green-700'
            )}
            variant="text-body-sm"
          >
            {description}
          </Typography>
        )}
        {!!actions && (
          <div className="mt-2 flex gap-3">
            {actions?.map((action) => (
              <button
                key={action.label}
                className={clsx(
                  variant === 'info' && 'text-cyan-700',
                  variant === 'gray' && 'text-gray-700',
                  variant === 'error' && 'text-red-700',
                  variant === 'warning' && 'text-amber-700',
                  variant === 'success' && 'text-green-800'
                )}
                type="button"
                onClick={action.onClick}
              >
                <Typography component="span" variant="text-label-sm">
                  {action.label}
                </Typography>
              </button>
            ))}
          </div>
        )}
      </div>
      {!!onDismiss && (
        <button
          className="absolute right-4 top-4"
          type="button"
          onClick={onDismiss}
        >
          <XIcon
            className={clsx(
              'h-[18px] w-[18px]',
              variant === 'info' && 'text-cyan-700',
              variant === 'gray' && 'text-gray-500',
              variant === 'error' && 'text-red-700',
              variant === 'warning' && 'text-amber-700',
              variant === 'success' && 'text-amplify-green-700'
            )}
          />
        </button>
      )}
    </div>
  );
};

export default Prompt;
