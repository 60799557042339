import React from 'react';
import { Typography } from '@ds';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const ChartNoData: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'mx-6 my-5 rounded-lg bg-gray-50 p-6 text-center',
        className ? className : ''
      )}
    >
      <Typography className="text-gray-700" variant="text-body-sm">
        No data available for this time period
      </Typography>
      {children}
    </div>
  );
};

export default ChartNoData;
