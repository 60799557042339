import { Typography } from '@ds';

export interface AvatarProps {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  size?: number;
}

export const Avatar: React.ComponentType<AvatarProps> = ({
  email,
  firstName,
  lastName,
  size = 40,
}) => {
  return (
    <div
      className="flex items-center justify-center rounded-full bg-secondary-grey-dark text-center text-white"
      style={{ height: size, width: size }}
    >
      <Typography style={{ fontSize: size * 0.4 }}>
        {firstName && lastName
          ? `${firstName.charAt(0).toUpperCase()}${lastName
              .charAt(0)
              .toUpperCase()}`
          : email.charAt(0).toUpperCase()}
      </Typography>
    </div>
  );
};

export default Avatar;
